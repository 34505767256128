import { useEffect, useState } from "react";
import {Modal, Row, Col, Button} from "react-bootstrap/";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import Form from "react-bootstrap/Form";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { uploadFile } from "../../api/services/attachments/files";
import { getServiceOrder, updateServiceOrderTrack } from "../../api/services/Eservice-api";
import { toast } from "react-toastify";
import "./electronic-service-request.css";
import i18n from "../../i18next";

const OrderEditModal = (props) => {
   const handleDownload = (url) => {
      window.open(url, "_blank");
   };

   const [selectedImage, setSelectedImage] = useState();
   const [selectedImageName, setSelectedImageName] = useState("");
   // const orderDetails = props.orderDetails;
   const [attachmentLink, setAttachmentLink] = useState("");
   const [selectedFiles, setSelectedFiles] = useState([]);
   const [selectedFilesName, setSelectedFilesName] = useState("");
   const [selectedCheckBox, setSelectedCheckBox] = useState([]);
   const [selectedCheckBoxName, setSelectedCheckBoxName] = useState("");
   const [orderDetails, setOrderDetails] = useState({})
   const [isLoading, setIsLoading] = useState(true)
   
   useEffect(() => {
      if (props.show && props.selectedCode) {
          getServiceOrder(props.selectedCode).then(res => {
              setOrderDetails(res.data);
              setIsLoading(false)
            }).catch(err => {
               console.log(err);
               setIsLoading(false)
          });
      }
      }, [props.selectedCode]);

   const { t } = useTranslation();
   let checkInput = true;

   const customInitialValues = {
      first_name: orderDetails?.first_name || "",
      second_name: orderDetails?.second_name || "",
      grandpa: orderDetails?.grandpa || "",
      family: orderDetails?.family || "",
      address: orderDetails?.address || "",
      phone: orderDetails?.phone || "",
      email: orderDetails?.email || "",
      note: orderDetails?.note || "",
   };

   orderDetails?.form_data?.forEach((item) => {
      customInitialValues[item.name_tag] = item.value || "";
   });

   const createValidationSchema = (formFields) => {
      const validationRules = {};

      formFields?.forEach((field) => {
         const { name_tag, type, validation_message } = field;
         if (field?.status == "required") {
            if (type != "image" && type != "file") {
               let fieldValidation = Yup.string().required(
                  validation_message ||
                     t("service_form.form_validation.required_field")
               );
               validationRules[name_tag] = Yup.string().required(
                  validation_message ||
                     t("service_form.form_validation.required_field")
               );
               if (type === "email") {
                  validationRules[name_tag] = fieldValidation.email(
                     validation_message ||
                        t("service_form.third_form.invalid_email")
                  );
               } else if (type === "url") {
                  // Add custom URL validation here
                  fieldValidation = fieldValidation.matches(
                     /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
                     validation_message || "Invalid URL format"
                  );
               } else {
                  validationRules[name_tag] = fieldValidation;
               }
            } else if (type === "file") {
               if (
                  selectedFiles != null ||
                  selectedFiles != undefined ||
                  selectedFiles != ""
               ) {
                  // checkInput = true;
               } else {
                  // checkInput = false;
               }
            } else if (type === "image") {
               if (
                  selectedImage != null ||
                  selectedImage != undefined ||
                  selectedImage != ""
               ) {
                  // checkInput = true;
               } else {
                  // checkInput = false;
               }
            }
         } else {
            let fieldValidation = Yup.string();
            validationRules[name_tag] = Yup.string();
            if (type === "email") {
               validationRules[name_tag] = fieldValidation
                  .notRequired()
                  .email(
                     validation_message ||
                        t("service_form.form_validation.required_field")
                  );
            } else if (type === "url") {
               // Add custom URL validation here
               validationRules[name_tag] = fieldValidation
                  .matches(
                     /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
                     validation_message ||
                        t("service_form.third_form.invalid_url")
                  )
                  .notRequired();
            } else if (type === "url") {
               fieldValidation
                  .matches(
                     /^[0-9]+$/,
                     t("service_form.third_form.invalid_phone")
                  )
                  .notRequired();
            } else {
               validationRules[name_tag] = fieldValidation.notRequired();
            }
         }

         // Add more validation rules for different field types if needed
         // Example: if (type === 'email') { validationRules[name_tag] = Yup.string().email(validation_message || 'Invalid email format'); }
      });
      return Yup.object().shape(validationRules);
   };

   const fixedFieldValidationSchema = Yup.object().shape({
      first_name: Yup.string().required(
         t("service_form.form_validation.please_enter_your_first_name")
      ),
      second_name: Yup.string().required(
         t("service_form.form_validation.please_enter_your_second_name")
      ),
      grandpa: Yup.string().required(
         t("service_form.form_validation.please_enter_your_third_name")
      ),
      family: Yup.string().required(
         t("service_form.form_validation.please_enter_your_fourth_name")
      ),
      address: Yup.string().required(
         t("service_form.form_validation.please_enter_the_address")
      ),
      phone: Yup.string()
         .matches(
            /^(\+\d{1,3})?[\s.-]?\(?\d{1,3}\)?[\s.-]?\d{1,4}[\s.-]?\d{1,4}[\s.-]?\d{1,9}$/,
            t("service_form.form_validation.please_enter_a_valid_mobile_number")
         )
         .required(
            t("service_form.form_validation.please_enter_the_mobile_number")
         ),
      email: Yup.string()
         .email(t("service_form.form_validation.please_enter_a_valid_email"))
         .required(t("service_form.form_validation.please_enter_your_e-mail")),
   });

   // Combine the two schemas
   const ValidationSchema = Yup.object().shape({
      ...fixedFieldValidationSchema.fields,
      ...createValidationSchema(orderDetails?.form_data).fields,
   });

   const hideLoadingToast = () => {
      toast.dismiss();
   };

   const updateOrderDetails = () => {
      getServiceOrder(props.selectedCode).then(res => {
          props.setOrderDetails(res.data);
      }).catch(err => {
          console.log(err);
      });
  };

   const handleSubmit = async (values, { setSubmitting }) => {

      try {
         if (selectedCheckBox.length > 0) {
            values[selectedCheckBoxName] = selectedCheckBox;
         }

         if (selectedFiles && selectedFiles[selectedFilesName]) {
            const formDataToUpload = new FormData();
            formDataToUpload.append("model", "service_orders");
            formDataToUpload.append("file", selectedFiles[selectedFilesName]);
            const uploadedFile = await uploadFile(formDataToUpload);
            values[selectedFilesName] = uploadedFile.data.data;
         }

         if (selectedImage) {
            const formDataToUpload = new FormData();
            formDataToUpload.append("model", "users");
            formDataToUpload.append("image", selectedImage[selectedImageName]);
            const uploadImage = await uploadImage(formDataToUpload);
            values[selectedImageName] = uploadImage.data.data;
         }

         const uploadedFileFields = orderDetails?.form_data
            ?.filter((item) => item.type === "file")
            ?.map((item, index) => [item.name_tag, uploadFile[index] || item?.value]);

         const formDataToSend = {
            first_name: values.first_name,
            second_name: values.second_name,
            grandpa: values.grandpa,
            family: values.family,
            address: values.address,
            phone: values.phone,
            email: values.email,
            note: values.note,
            form_data: JSON.stringify({
               ...Object.fromEntries(
                  orderDetails?.form_data
                     ?.filter(
                           (item) => item.type !== "file" && item.type !== "file"
                     )
                     ?.map((item) => [item.name_tag, values[item.name_tag]])
               ),
               ...Object.fromEntries(uploadedFileFields),
               ...(values[selectedFilesName]
                  ? { [selectedFilesName]: values[selectedFilesName] }
                  : {}),
         }),
         };

         if (orderDetails.code) {
            await updateServiceOrderTrack(orderDetails?.code, formDataToSend);
            hideLoadingToast(); // Hide loading toast
            toast.success(t("All.request_modified"));
            setSubmitting(false);
            updateOrderDetails(); // Call the onUpdate callback function
            props.onHide();
         }
         setSubmitting(false);
      } catch (error) {
         hideLoadingToast(); // Hide loading toast
         toast.error(t("All.error_occurred"));
         console.log(error);
         setSubmitting(false);
      }
   };

   return (
      <>
         {/* <ToastContainer /> */}
         {isLoading ? <>
            <Modal
            style={{direction: i18n.language == "en" ? "ltr" : "rtl", minHeight: "550px", minWidth: "550px"}}
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={"orderEditModal custom-modal edit-request-modal d-flex justify-content-center align-items-center"}
         >
            <Modal.Header closeButton>
               <Modal.Title
                  id="contained-modal-title-vcenter"
                  className="secondaryColor fs-4 fw-bold"
               >
                  {t("personalcontent.edit_service")}
               </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{minHeight: "400px", minWidth: "500px"}} className="d-flex justify-content-center align-items-center">
            <div
               className={"d-flex justify-content-center align-items-center"}
            >
            <div className="spinner-border text-primary" role="status">
               <span className="visually-hidden">Loading...</span>
            </div>
         </div>
         </Modal.Body>

         </Modal>

         </> :
         <Modal
            style={{direction: i18n.language == "en" ? "ltr" : "rtl"}}
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={"orderEditModal custom-modal edit-request-modal"}
         >
            <Modal.Header closeButton>
               <Modal.Title
                  id="contained-modal-title-vcenter"
                  className="secondaryColor fs-4 fw-bold"
               >
                  {t("personalcontent.edit_service")}
               </Modal.Title>
            </Modal.Header>
            <Formik
               initialValues={customInitialValues}
               validationSchema={ValidationSchema}
               onSubmit={handleSubmit}
            >
               {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  values,
                  touched,
                  isValid,
                  errors,
                  setFieldValue,
               }) => (
                  <FormikForm noValidate onSubmit={handleSubmit}>
                     <Modal.Body>
                        {/*PERSONAL DATA*/}
                        {console.log(errors)}
                        <div className={"service-request-form-title mb-2"}>
                           {t("personalcontent.personal_profile")}
                        </div>
                        <Form.Group controlId="first_name" className={"mb-2"}>

                            <Row className={"personal_info"}>
                                <Col lg={3} className={"my-2"}>
                                    <Form.Label className={"mb-2"}>
                                        {t("service_form.form_validation.first_Name")}
                                    </Form.Label>
                                    <Form.Control
                                        name={"first_name"}
                                        onChange={handleChange}
                                        isInvalid={!!errors.first_name}
                                        // isValid={
                                        //    values.first_name !== "" &&
                                        //    !errors.first_name
                                        // }
                                        value={values.first_name}
                                        type="text"
                                        placeholder={t(
                                            "service_form.form_validation.first_Name"
                                        )}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className={"text-nowrap"}
                                    >
                                        {errors.first_name}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col lg={3} className={"my-2"}>
                                    <Form.Label className={"mb-2"}>
                                        {t("service_form.form_validation.the_second_name")}
                                    </Form.Label>
                                    <Form.Control
                                        name={"second_name"}
                                        onChange={handleChange}
                                        isInvalid={!!errors.second_name}
                                        // isValid={
                                        //    values.second_name !== "" &&
                                        //    !errors.second_name
                                        // }
                                        value={values.second_name}
                                        type="text"
                                        placeholder={t(
                                            "service_form.form_validation.the_second_name"
                                        )}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className={"text-nowrap"}
                                    >
                                        {errors.second_name}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col lg={3} className={"my-2"}>
                                    <Form.Label className={"mb-2"}>
                                        {t("service_form.form_validation.third_name")}
                                    </Form.Label>
                                    <Form.Control
                                        name={"grandpa"}
                                        onChange={handleChange}
                                        isInvalid={!!errors.grandpa}
                                        // isValid={
                                        //    values.grandpa !== "" && !errors.grandpa
                                        // }
                                        value={values.grandpa}
                                        type="text"
                                        placeholder={t(
                                            "service_form.form_validation.third_name"
                                        )}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className={"text-nowrap"}
                                    >
                                        {errors.grandpa}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col lg={3} className={"my-2"}>
                                    <Form.Label className={"mb-2"}>
                                        {t("service_form.form_validation.fourth_name")}
                                    </Form.Label>
                                    <Form.Control
                                        name={"family"}
                                        onChange={handleChange}
                                        isInvalid={!!errors.family}
                                        // isValid={
                                        //    values.family !== "" && !errors.family
                                        // }
                                        value={values.family}
                                        type="text"
                                        placeholder={t(
                                            "service_form.form_validation.family_name"
                                        )}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className={"text-nowrap"}
                                    >
                                        {errors.family}
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                        </Form.Group>
                        <div className={"mb-2"}>
                           <Form.Group
                              className="mb-3"
                              controlId="formBasicAddress"
                           >
                              <Form.Label className={"mb-2"}>
                                  {t("service_form.first_form.address")}
                              </Form.Label>
                              <Form.Control
                                 name={"address"}
                                 type="text"
                                 placeholder={t(
                                    "service_form.first_form.address"
                                 )}
                                 onChange={handleChange}
                                 isInvalid={!!errors.address}
                                 // isValid={
                                 //    values.address !== "" && !errors.address
                                 // }
                                 value={values.address}
                              />
                              <Form.Control.Feedback
                                 type="invalid"
                                 className={"text-nowrap d-block"}
                              >
                                 {errors.address}
                              </Form.Control.Feedback>
                           </Form.Group>
                        </div>
                        <div className={"mb-2"}>
                           <div className={"d-flex justify-content-between gap-2"}>
                              <Form.Group
                                 className={"d-flex flex-column w-50"}
                                 controlId="formBasicEmail"
                              >
                                 <Form.Label className={"mb-2"}>
                                    {t("personalcontent.email")}
                                 </Form.Label>
                                 <Form.Control
                                    name={"email"}
                                    type="email"
                                    placeholder="name@example.com"
                                    onChange={handleChange}
                                    isInvalid={!!errors.email}
                                    // isValid={
                                    //    values.email !== "" && !errors.email
                                    // }
                                    value={values.email}
                                 />
                                 <Form.Control.Feedback
                                    type="invalid"
                                    className={"text-nowrap d-block"}
                                 >
                                    {errors.email}
                                 </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                 className={"d-flex flex-column w-50"}
                                 controlId="formBasicPhone"
                              >
                                 <Form.Label className={"mb-2"}>
                                    {t("service_form.fourth_form.phone_number")}
                                 </Form.Label>
                                 <Form.Control
                                    name={"phone"}
                                    type="tel"
                                    placeholder={`${t("service_form.fourth_form.phone_number")}`}
                                    onChange={handleChange}
                                    isInvalid={!!errors.phone}
                                    // isValid={
                                    //    values.phone !== "" && !errors.phone
                                    // }
                                    dir={i18n.language == "en" ? "ltr" : "rtl"}
                                    value={values.phone}
                                 />
                                 <Form.Control.Feedback
                                    type="invalid"
                                    className={"text-nowrap d-block"}
                                 >
                                    {errors.phone}
                                 </Form.Control.Feedback>
                              </Form.Group>
                           </div>
                        </div>
                        <Form.Group controlId="formBasicDetails">
                           <Form.Label className={"secondaryColor fs-6"}>
                              {t("service_form.form_validation.additional_info")}
                           </Form.Label>
                           <Form.Control
                              name={"note"}
                              as="textarea"
                              rows={3}
                              placeholder={t(
                                 "service_form.form_validation.additional_info"
                              )}
                              onChange={handleChange}
                              value={values.note}
                           />
                        </Form.Group>
                        <hr />
                         <div className={"service-request-form-title mb-2"}>
                             {t("personalcontent.order_data")}
                         </div>
                        <Row className={"fw-bold fs-6 gy-3 mt-1"}>
                           {/*OTHER FORM DATA FROM FORM_DATA*/}
                           {orderDetails?.form_data?.map((item, index) => (
                              <Col lg={6} key={index}>
                                 {item.type_tag === "select" && (
                                    <Form.Group controlId={item?.name_tag}>
                                       <Form.Label
                                          className={`${
                                             item?.status === "required"
                                                ? "required"
                                                : ""
                                          }`}
                                       >
                                          {item?.name}
                                       </Form.Label>
                                       <Form.Select
                                          label={item?.name}
                                          aria-label={item?.name}
                                          className={"mx-2"}
                                          onChange={handleChange}
                                          // isValid={
                                          //    values[item?.name_tag] !== "" &&
                                          //    !errors[item?.name_tag]
                                          // }
                                          isInvalid={!!errors.item?.name_tag}
                                          value={values[item?.name_tag] || ""}
                                       >
                                          <option disabled value="">
                                             {item?.placeholder}
                                          </option>
                                          {item?.data_choose?.map(
                                             (option, index) => (
                                                <option
                                                   key={index}
                                                   value={option}
                                                >
                                                   {option}
                                                </option>
                                             )
                                          )}
                                       </Form.Select>
                                       <Form.Control.Feedback
                                          type="invalid"
                                          className={"text-nowrap d-block"}
                                       >
                                          {errors[item?.name_tag]}
                                       </Form.Control.Feedback>
                                    </Form.Group>
                                 )}

                                 {item.type_tag === "textArea" && (
                                    <Form.Group
                                       className="mx-2"
                                       controlId={item?.name_tag}
                                    >
                                       <Form.Label
                                          className={`${
                                             item?.status == "required"
                                                ? "required"
                                                : ""
                                          }`}
                                       >
                                          {item?.name}
                                       </Form.Label>
                                       <Form.Control
                                          as="textarea"
                                          name={item?.name_tag}
                                          type={item?.type}
                                          placeholder={item?.placeholder}
                                          onChange={handleChange}
                                          // isValid={
                                          //    values[item?.name_tag] !== "" &&
                                          //    !errors[item?.name_tag]
                                          // }
                                          isInvalid={!!errors.item?.name_tag}
                                          value={values[item?.name_tag]}
                                       />
                                       <Form.Control.Feedback
                                          type="invalid"
                                          className={"text-nowrap d-block"}
                                       >
                                          {errors[item?.name_tag]}
                                       </Form.Control.Feedback>
                                    </Form.Group>
                                 )}

                                 {item.type_tag === "input" ? (
                                    item.type === "radio" ? (
                                       <Form.Group
                                          className="mx-2"
                                          controlId={item?.name_tag}
                                       >
                                          <Form.Label
                                             className={`${
                                                item?.status === "required"
                                                   ? "required"
                                                   : ""
                                             }`}
                                          >
                                             {item?.name}
                                          </Form.Label>
                                          {item.data_choose?.map(
                                             (radio, index) => (
                                                <Form.Check
                                                   reverse
                                                   onChange={handleChange}
                                                   isInvalid={
                                                      !!errors.item?.name_tag
                                                   }
                                                   // isValid={
                                                   //    values[item?.name_tag] !==
                                                   //       "" &&
                                                   //    !errors[item?.name_tag]
                                                   // }
                                                   value={radio}
                                                   key={index}
                                                   type="radio"
                                                   label={radio}
                                                   defaultChecked={index === 0}
                                                   name={item?.name_tag}
                                                   id={"radio" + index}
                                                />
                                             )
                                          )}
                                       </Form.Group>
                                    ) : item.type === "checkbox" ? (
                                       <Form.Group
                                          className="mx-2"
                                          controlId={item?.name_tag}
                                       >
                                          <Form.Label
                                             className={`${
                                                item?.status === "required"
                                                   ? "required"
                                                   : ""
                                             }`}
                                          >
                                             {item?.name}
                                          </Form.Label>
                                          {item?.data_choose?.map(
                                             (checkbox, index) => (
                                                <Form.Check
                                                   reverse
                                                   onChange={(event) => {
                                                      if (
                                                         event.target.checked
                                                      ) {
                                                         setSelectedCheckBox([
                                                            ...new Set(
                                                               selectedCheckBox
                                                            ).add(
                                                               event.target
                                                                  .value
                                                            ),
                                                         ]);
                                                         setSelectedCheckBoxName(
                                                            item.name_tag
                                                         );
                                                      } else {
                                                         setSelectedCheckBox(
                                                            selectedCheckBox.filter(
                                                               (item) =>
                                                                  item !==
                                                                  event.target
                                                                     .value
                                                            )
                                                         );
                                                      }
                                                   }}
                                                   isInvalid={
                                                      !!errors.item?.name_tag
                                                   }
                                                   // isValid={
                                                   //    values[item?.name_tag] !==
                                                   //       "" &&
                                                   //    !errors[item?.name_tag]
                                                   // }
                                                   value={checkbox}
                                                   key={index}
                                                   type="checkbox"
                                                   label={checkbox}
                                                   name={item?.name_tag}
                                                   id={"checkbox" + index}
                                                />
                                             )
                                          )}
                                       </Form.Group>
                                    ) : item.type === "file" ? (
                                       <Form.Group controlId="file">
                                          <Form.Label
                                             className={`${
                                                item?.status === "required"
                                                   ? "required"
                                                   : ""
                                             } secondaryColor fs-6`}
                                          >
                                             {item.name}
                                          </Form.Label>
                                          <input
                                             className={`position-relative form-control ${
                                                touched.file && errors.file
                                                   ? "is-invalid"
                                                   : ""
                                             }`}
                                             // isValid={
                                             //    values[item?.name_tag] !== "" &&
                                             //    !errors[item?.name_tag]
                                             // }
                                             type={item.type}
                                             name={item.name_tag}
                                             accept=".pdf"
                                             onChange={(event) => {
                                                setSelectedFiles({
                                                   ...selectedFiles,
                                                   [item.name_tag]:
                                                      event.target.files[0] || item?.value,
                                                });
                                                setSelectedFilesName(
                                                   item.name_tag
                                                );
                                             }}
                                             onBlur={handleBlur}
                                          />
                                          {attachmentLink || item.value ? (
                                             <a
                                                href={
                                                   attachmentLink || item.value
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                             >
                                                <bdi style={{color: "#425260"}}>
                                                   {item?.value
                                                      ?.split("/")
                                                      ?.pop()}
                                                </bdi>
                                             </a>
                                          ) : null}
                                          <ErrorMessage
                                             name={item.name_tag}
                                             component="div"
                                             className="invalid-feedback"
                                          />
                                       </Form.Group>
                                    ) : item.type === "image" ? (
                                       <>
                                          <Form.Group className="position-relative mb-3">
                                             <div
                                                className={
                                                   "d-flex flex-column p-2"
                                                }
                                             >
                                                <label
                                                   className={`${
                                                      item?.status == "required"
                                                         ? "required"
                                                         : ""
                                                   } custom-file-label`}
                                                   htmlFor={item.name_tag}
                                                >
                                                   {item?.name}
                                                </label>
                                                <Form.Control
                                                   type="file"
                                                   name={item.name_tag}
                                                   accept="image/*"
                                                   onChange={(event) => {
                                                      setSelectedImage({
                                                         [item.name_tag]:
                                                            event.target
                                                               .files[0],
                                                      });
                                                      setSelectedImageName(
                                                         item.name_tag
                                                      );
                                                   }}
                                                   className="custom-file-input"
                                                   id={item.name_tag}
                                                   aria-describedby={
                                                      item.name_tag
                                                   }
                                                />
                                             </div>
                                             <Form.Control.Feedback
                                                type="invalid"
                                                className={`text-nowrap ${
                                                   errors.item?.name_tag !==
                                                   null
                                                      ? "d-block"
                                                      : "d-none"
                                                }`}
                                             >
                                                {errors.item?.name_tag}
                                             </Form.Control.Feedback>
                                          </Form.Group>
                                       </>
                                    ) : (
                                       <Form.Group controlId={item.name_tag}>
                                          <Form.Label
                                             className={`secondaryColor fs-6 ${
                                                item?.status == "required"
                                                   ? "required"
                                                   : ""
                                             }`}
                                          >
                                             {item.name}
                                          </Form.Label>
                                          <Form.Control
                                             type={item.type}
                                             name={item.name_tag}
                                             placeholder={item.placeholder}
                                             onChange={handleChange}
                                             value={values[item.name_tag]}
                                             isInvalid={!!errors.item?.name_tag}
                                             // isValid={
                                             //    values[item?.name_tag] !== "" &&
                                             //    !errors[item?.name_tag]
                                             // }
                                          />
                                          <Form.Control.Feedback
                                             type="invalid"
                                             className={"text-nowrap d-block"}
                                          >
                                             {errors[item?.name_tag]}
                                          </Form.Control.Feedback>
                                       </Form.Group>
                                    )
                                 ) : null}
                              </Col>
                           ))}
                        </Row>
                         <div className="py-3 text-center mt-3">
                             <Button
                                 className={"move-back-btn maxBtnWidht mt-1"}
                                 onClick={props.onHide}
                             >
                                 {t("personal_profile.move_back")}
                             </Button>
                             <Button
                                 disabled={isSubmitting}
                                 type={"submit"}
                                 className={"save-service-btn mx-2 maxBtnWidht mt-1"}
                             >
                                 {isSubmitting && <span className="spinner-border spinner-border-sm "></span>}
                                 {t("personal_profile.save_changes")}
                             </Button>
                         </div>
                     </Modal.Body>
                  </FormikForm>
               )}
            </Formik>
         </Modal>
         }
      </>
   );
};

export default OrderEditModal;
