import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import { uploadFile } from "../../api/services/attachments/files";
import {postServiceOrderTrack} from "../../api/services/Eservice-api";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import "./electronic-service-request.css";
import i18n from '../../i18next';

const hideLoadingToast = () => {
    toast.dismiss();
};

const SendAttachmentModal = ({addTrack, ...props}) => {
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        notes: Yup.string().required(t("personalcontent.mustNotes")),
        file: Yup.mixed()
            .required(t('personalcontent.uploadFile'))
            .test('fileSize', t("personalcontent.fileMustBe"), function(value) {
                if (value) {
                    const fileSize = value.size / 1024 / 1024; // Size in MB
                    return fileSize <= 100;
                }
                return true; // Allow empty file field
            }),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const fileData = new FormData();
            fileData.append('model', "service_orders");
            fileData.append('file', values.file);

            const uploadResponse = await uploadFile(fileData); // Upload the file

            const formData = new FormData();
            formData.append('code', props?.code);
            formData.append('user_note', values.notes);
            formData.append('attachment', uploadResponse.data.data); // Include the response note in the formData

            await postServiceOrderTrack(formData); // Submit the formData
            hideLoadingToast(); // Hide loading toast
            toast.success(i18n.language == "ar" ? "تم تقديم الملاحظة بنجاح" : "The note has been submitted successfully");
            addTrack({
                code: props.code,
                user_note: values.notes,
                attachment: uploadResponse.data.data
            });
            setSubmitting(false);
            props.onHide();
        } catch (error) {
            hideLoadingToast(); // Hide loading toast
            toast.error(i18n.language == "ar" ? "حدث خطأ اثناء تقديم الملاحظة!" : "An error occurred while submitting the note");
            setSubmitting(false);
        }
    };

    return (
        <>
            {/* <ToastContainer /> */}
            <Modal
                style={{direction: i18n.language == "en" ? "ltr" : "rtl"}}
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className={"sendAttachmentModal custom-modal edit-request-modal"}
            >
                <Formik
                    initialValues={{
                        notes: '',
                        file: null,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                          handleSubmit,
                          handleBlur,
                          isSubmitting,
                          touched,
                          errors,
                          setFieldValue,
                      }) => (
                        <FormikForm noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    {t("personalcontent.send_attachment")}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group controlId="file" className={"my-2"}>
                                    <Form.Label>
                                        {t("service_file")}
                                    </Form.Label>
                                    <input
                                        type="file"
                                        className={`position-relative form-control ${
                                            touched.file && errors.file ? "is-invalid" : ""
                                        }`}
                                        accept=".pdf, .doc"
                                        name="file"
                                        onChange={(event) => {
                                            const file = event.currentTarget.files[0];
                                            setFieldValue("file", file);
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <ErrorMessage
                                        name="file"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                </Form.Group>

                                <Form.Group controlId="notes" className={"my-2"}>
                                    <Form.Label>
                                        {t("personalcontent.notes")}
                                    </Form.Label>
                                    <Field
                                        as="textarea"
                                        placeholder={i18n.languages == "ar" ? "ارسل ملاحظات" : "send notes"}
                                        rows={3}
                                        name="notes"
                                        className={`form-control ${
                                            touched.notes && errors.notes ? "is-invalid" : ""
                                        }`}
                                    />
                                    <ErrorMessage
                                        name="notes"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                </Form.Group>
                                <div className="py-3 text-center mt-3">
                                    <Button
                                        className={"move-back-btn maxBtnWidht mt-1"}
                                        onClick={props.onHide}
                                    >
                                        {t("personal_profile.move_back")}
                                    </Button>
                                    <Button
                                        style={{fontSize: "18px"}}
                                        disabled={isSubmitting}
                                        type={"submit"}
                                        className={"save-service-btn mx-2 maxBtnWidht mt-1"}
                                    >
                                        {isSubmitting && <span className="spinner-border spinner-border-sm "></span>}
                                        {t("personalcontent.send")}
                                    </Button>
                                </div>
                            </Modal.Body>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </>
    );
};

export default SendAttachmentModal;