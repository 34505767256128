import { createContext, useState } from 'react';

export const LoadingContext = createContext();

function LoadingProvider({children}) {
    const [pageLoading, setPageLoading] = useState(false);

    return (
        <LoadingContext.Provider value={{ pageLoading, setPageLoading }}>
            {children}
        </LoadingContext.Provider>
    );
}

export default LoadingProvider;