import {Outlet} from "react-router-dom";
import HelpAndSupport from "../../components/HelpAndSupport/help-and-support";
import Footer from "../../components/Footer/Footer";
import styles from "./Layout.module.css";
import Header from "../../components/Header/Header";
import {useEffect, useState} from "react";
import {getSettingsData} from "../../api/services/layout&settings/settings";
import ChatWhatsApp from "../../components/ChatWhatsApp/ChatWhatsApp";
import BlankPageLoader from "../../components/LoadingContext/PageLoading/blank-page-loader";
// import PageLoader from "../../components/LoadingContext/PageLoading/PageLoader";

const Layout = () => {
    const [settingsData, setSettingsData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        getSettingsData()
            .then((res) => {
                setSettingsData(res.data);
            })
            .catch((err) => {
                console.log("error happened", err);
            }).finally(() => setIsLoaded(true));
    }, []);

    if (!isLoaded) {
        return <>
            <BlankPageLoader />
        </>;
    }
    return (<>
        <Header links={settingsData}/>
        <div className={styles.bodyInner}>
            <HelpAndSupport/>
            <Outlet/>
        </div>
        <a href={"https://wa.me/" + settingsData?.whatsapp?.replace(/\D/g, "")}
        target={"_blank"}
        aria-label="phone"
        >
            <ChatWhatsApp/>
        </a>
        <Footer links={settingsData}/>
    </>);
};

export default Layout;