import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import "./electronic-service-request.css";
import Button from "react-bootstrap/Button";

export const ElectronicServiceRequestCancelModal = ({ show, onHide, order, cancelService }) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div>
                <Modal
                    show={show}
                    onHide={onHide}
                    keyboard={false}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className={"sendAttachmentModal custom-modal edit-request-modal"}
                    dir={i18n.language === "en" ? "ltr" : "rtl"}
                >
                    <Modal.Body className={"py-3"}>
                        <div className={"d-flex flex-column align-items-center pt-2"}>
                            <svg width="50" height="50" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 3.98665C11.78 3.76665 9.54667 3.65332 7.32 3.65332C6 3.65332 4.68 3.71999 3.36 3.85332L2 3.98665" stroke="#EB5757" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M5.66797 3.31337L5.81464 2.44004C5.9213 1.80671 6.0013 1.33337 7.12797 1.33337H8.87464C10.0013 1.33337 10.088 1.83337 10.188 2.44671L10.3346 3.31337" stroke="#EB5757" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12.5669 6.09338L12.1336 12.8067C12.0603 13.8534 12.0003 14.6667 10.1403 14.6667H5.86026C4.00026 14.6667 3.94026 13.8534 3.86693 12.8067L3.43359 6.09338" stroke="#EB5757" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.88672 11H9.10672" stroke="#EB5757" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.33203 8.33337H9.66536" stroke="#EB5757" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <div className={"service-request-modal-title my-3"}>
                                {t("personalcontent.cancel_service")}
                            </div>
                            <p style={{color: "#425260"}}>{t("personalcontent.cancel_service_msg")}</p>
                        </div>
                        <div className="py-3 text-center">
                            <Button
                                className={"move-back-btn maxBtnWidht mt-1"}
                                onClick={onHide}
                            >
                                {t("personal_profile.move_back")}
                            </Button>
                            <Button
                                className={"delete-service-btn mx-2 maxBtnWidht mt-1"}
                                onClick={() => {
                                    cancelService(order.code)
                                    onHide()
                                }}
                            >
                                {t("personalcontent.cancel_service")}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
}