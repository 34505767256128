import { axios, baseURL, config, commonURL } from "../../axios";


export const getNotifications = async (page = 1, limit = 3) => {
    try {
        const { data } = await axios.get(commonURL + `notifications?limit=${limit}&page=${page}`, config);
        return data;
    } catch (error) {
        throw error;
    }
}

export const hiddenNotification = async (id) => {
    try {
        const { data } = await axios.put(commonURL + `notifications/hidden/${id}`, {}, config);
        return data;
    } catch (error) {
        throw error;
    }
}

export const getNotificationsCount = async () => {
    try {
        const { data } = await axios.get(commonURL + `notifications/count`, config);
        return data;
    } catch (error) {
        throw error;
    }
}


export const readNotification = async (id) => {
    try {
        const { data } = await axios.get(commonURL + `notifications/show/${id}`, config);
        return data;
    } catch (error) {
        throw error;
    }
}