import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en/en.json";
import translationAR from "./locales/ar/ar.json";
import I18NextHttpBackend from "i18next-http-backend";

const resources = {
    en: {
        translation: translationEN
    },
    ar: {
        translation: translationAR
    }
};

i18n
    .use(I18NextHttpBackend)
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: i18n.language,
        fallbackLng: i18n.language,
        fallbackNS: "common",
        debug: true,
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        },
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
            crossDomain: true,
            withCredentials: true,
            queryStringParams: { "Accept-Language": i18n.language },
        },
    });

export default i18n;

