import { axios, baseURL, config } from "../axios";

export const getFooterLinks = () => {
  return axios
    .get("https://darah-dev.4explain.net/api/v1/portal/footer", config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};