import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";

// Start Other Imports
import { CartProvider } from "./context/CartContext/CartContext";
import { AuthProvider } from "./context/authContext/authContext";
import Layout from "./pages/Layout/Layout";
import Notfound from "./pages/Notfound/Notfound";
import { useZoom } from './context/zoomContext/ZoomContext'
import { useContrast } from './context/contrastContext/ContrastContext'
import ProtectedRoute from "./protected-route";
import BlankPageLoader from "./components/LoadingContext/PageLoading/blank-page-loader";
// import {useAuth} from "./context/authContext/authContext";
import LoadingProvider from "./components/LoadingContext/LoadingContext";
import { TermsAndConditions } from "./pages/terms-and-conditions/TermsAndConditions";
// End Other Imports

// Start Unused Components
import AboutUs from "./pages/AboutUs/AboutUs";
import FilterBooks from "./pages/FilterBooks/FilterBooks";
import PaymentMethods from "./pages/Payment/payment-methods";
import DepartmentsAndCenters from "./pages/DepartmentsAndCenters/departments-and-centers";
import FreeAccessCategory from './pages/FreeAccess-category/FreeAccessCategory';
import PurchaseRequest from "./pages/PersonalProfile/purchase-request";
import ElectronicServiceRequest from "./pages/PersonalProfile/electronic-service-request";
import AuthorWork from "./pages/AuthorsOpinions/Auther-work";
import AutherCV from "./pages/AuthorsOpinions/Auther-CV";
import Authers from "./pages/AuthorsOpinions/Auther";
import LatestBooks from "./pages/LatestBooks/latest-books";
import AudioBooks from "./pages/AudioBooks/audio-books";
import CheckoutMsgConfirmation from "./pages/CheckoutPage/checkout-msg-confirmation";
import LocationMap from "./pages/CheckoutPage/location-map";
// End Unused Components

const HomeLazyLoading = React.lazy(() => import("./pages/Home/Home"));

// Start Favorites
const FavoritesLazyLoading = React.lazy(() => import("./pages/favoriteBooks/favorites"));
// End Favorites

// Start Cart
const CartLazyLoading = React.lazy(() => import("./pages/Cart/Cart"));
const CheckoutPageLazyLoading = React.lazy(() => import("./pages/CheckoutPage/CheckoutPage"));
const NewCheckoutLazyLoading = React.lazy(() => import("./pages/CheckoutPage/NewCheckout"));
// End Cart

// Start Book Details
const BookDetailsLazyLoading = React.lazy(() => import("./pages/BookDetails/BookDetails"));
const BookViewLazyLoading = React.lazy(() => import("./pages/BookView/BookView"));
// End Book Details

// Start Help&Support
// const ContactUsLazyLoading = React.lazy(() => import("./pages/ContactUs/ContactUs"));
// const SearchGateLazyLoading = React.lazy(() => import("./pages/SearchGate/search-gate"));
const SignLanguageLazyLoading = React.lazy(() => import("./pages/SignLanguage/SignLanguage"));
const FAQLazyLoading = React.lazy(() => import("./pages/FAQ/FAQ"));
const ServicesForPeopleLazyLoading = React.lazy(() => import("./pages/Services-for-people/Services-for-people"));
const BeneficiaryVoiceLazyLoading = React.lazy(() => import("./pages/BeneficiaryVoice/beneficiary-voice"));
// End Help&Support

// Start Profile
const PersonalProfileLazyLoading = React.lazy(() => import("./pages/PersonalProfile/personal-profile"));
// End Profile

// Start Account Managment
const AccountManagementLazyLoading = React.lazy(() => import("./pages/PersonalProfile/account-management"));
const EmailVerificationLazyLoading = React.lazy(() => import("./pages/Login/emailVerification"));
const VerificationLazyLoading = React.lazy(() => import("./pages/Login/verification"));
const ResetPasswordLazyLoading = React.lazy(() => import("./pages/Login/reset-password"));
// End Account Managment

const PrivacyPolicyLazyLoading = React.lazy(() => import("./pages/Privacy-Policy/Privacy-Policy"));


function App() {
  // const ProtectedRoute = useAuth();
  //
  // const ele = localStorage.getItem("token");
  //
  // const defaultProtectedRouteProps = {
  //   isAuthenticated: ele === null || ele === "" ? false : true,
  //   authenticationPath: "/login",
  // };
  
  const { zoomLevel } = useZoom();
  const { contrastLevel } = useContrast();
  return (
    <LoadingProvider>
          <div className={`zoomed`}>
        <BrowserRouter>
          <AuthProvider>
            <CartProvider>
          <Routes>
            <Route element={<Layout />}>

            <Route path="/" element={<React.Suspense fallback={<BlankPageLoader />}><HomeLazyLoading /></React.Suspense>} />
            <Route path="/checkOutMass" element={<CheckoutMsgConfirmation/>} />

            {/* Start Book Details */}
            <Route path="/books/:slug" element={<React.Suspense fallback={<BlankPageLoader />}><BookDetailsLazyLoading /></React.Suspense>} />
            <Route path="/books/:slug/view" element={<React.Suspense fallback={<BlankPageLoader />}><BookViewLazyLoading /></React.Suspense>} />
            {/* End Book Details */}

            {/* Start Help&Support */}
            <Route path="/helpandsupport/faq" element={<React.Suspense fallback={<BlankPageLoader />}><FAQLazyLoading /></React.Suspense>} />
            <Route path="/beneficiaryVoice" element={<React.Suspense fallback={<BlankPageLoader />}><BeneficiaryVoiceLazyLoading /></React.Suspense>} />
            {/* <Route path="/helpandsupport/signlanguage" element={<React.Suspense fallback={<BlankPageLoader />}><SignLanguageLazyLoading /></React.Suspense>} /> */}
            <Route path="/accessibility-services" element={<React.Suspense fallback={<BlankPageLoader />}><ServicesForPeopleLazyLoading /></React.Suspense>} />
            {/* End Help&Support */}

            {/* Start Favorites */}
            <Route path="/favorites" element={<React.Suspense fallback={<BlankPageLoader />}><FavoritesLazyLoading /></React.Suspense>} />
            {/* End Favorites */}

            {/* Start Cart */}
            <Route path="/cart" element={<React.Suspense fallback={<BlankPageLoader />}><CartLazyLoading /></React.Suspense>} />
            <Route path="/checkout" element={<React.Suspense fallback={<BlankPageLoader />}><CheckoutPageLazyLoading /></React.Suspense>} />
            {/* <Route path="/map" element={<React.Suspense fallback={<BlankPageLoader />}><NewCheckoutLazyLoading /></React.Suspense>} /> */}
            {/* End Cart */}
            
            {/* Start Account Managment */}
            <Route path="/account-management" element={<React.Suspense fallback={<BlankPageLoader />}><AccountManagementLazyLoading />  </React.Suspense>} />
            <Route path="/verification" element={<React.Suspense fallback={<BlankPageLoader />}><VerificationLazyLoading /></React.Suspense>} />
            {/* End Account Managment */}

            <Route path="/privacy-policy" element={<React.Suspense fallback={<BlankPageLoader />}><PrivacyPolicyLazyLoading /></React.Suspense>} />
            <Route path="/terms-conditions/register" element={<TermsAndConditions/>} />
            <Route path="location" element={<LocationMap />} />



              {/* <Route path="/contact-us" element={<React.Suspense fallback={<BlankPageLoader />}><ContactUsLazyLoading /></React.Suspense>} /> */}
              {/* <Route path="/categories/products" element={<FilterBooks />} /> */}
              {/* <Route path="/categories/products/latest-products" element={<LatestBooks />} /> */}
              {/* <Route path="/categories/products/audio-books" element={<AudioBooks />} /> */}
              {/* <Route path="/categories/books/:slug" element={<React.Suspense fallback={<BlankPageLoader />}><BookDetailsLazyLoading /></React.Suspense>} /> */}
              {/* <Route path="/categories/books/:slug/view" element={<React.Suspense fallback={<BlankPageLoader />}><BookViewLazyLoading /></React.Suspense>} /> */}
              {/* <Route path="/payment" element={<PaymentMethods />} /> */}
              {/* <Route path="/categories/free-access-books" element={<FreeAccessCategory />} /> */}
              {/* <Route path="/about-us" element={<AboutUs />} /> */}
              {/* <Route path="/departments" element={<DepartmentsAndCenters />} /> */}
              {/* <Route path="/profile/PurchaseRequest" element={<ProtectedRoute />}> */}
                {/* <Route path="/profile/PurchaseRequest" element={<PurchaseRequest />}/> */}
              {/* </Route> */}
              {/* <Route path="/profile/ElectronicServiceRequest" element={<ProtectedRoute />}>
                <Route path="/profile/ElectronicServiceRequest" element={<ElectronicServiceRequest />}/>
              </Route> */}
              {/* <Route path="/authers/autherwork/:userName" element={<AuthorWork />} /> */}
              {/* <Route path="/authers/authercv/:userName" element={<AutherCV />} /> */}
              {/* <Route path="/authers" element={<Authers />} /> */}
              {/* <Route path="/searchgate" element={<React.Suspense fallback={<BlankPageLoader />}><SearchGateLazyLoading /></React.Suspense>} />               */}
              {/* <Route
                path="/categories/:slug/products"
                element={<FilterBooks />} 
              /> */}
              

              {/* Start Profile */}
              <Route path="/profile" element={<ProtectedRoute />}>
                <Route path="/profile" element={<React.Suspense fallback={<BlankPageLoader />}><PersonalProfileLazyLoading /></React.Suspense>} />
              </Route>
              {/* End Profile */}

            </Route>
            
            <Route path="/verificationEmail" element={<React.Suspense fallback={<BlankPageLoader />}><EmailVerificationLazyLoading /></React.Suspense>} />
            <Route path="/resetPassword" element={<React.Suspense fallback={<BlankPageLoader />}><ResetPasswordLazyLoading /></React.Suspense>} />
            <Route path="/resetPassword/:id" element={<React.Suspense fallback={<BlankPageLoader />}><ResetPasswordLazyLoading /></React.Suspense>} />
            <Route path="*" element={<Notfound />} />
          </Routes>
            </CartProvider>
          </AuthProvider>
        </BrowserRouter>
        </div>
    </LoadingProvider>
  );
}

export default App;
