import MenuItems from "./MenuItems";
import "./header.css";
import { useTranslation } from "react-i18next";
const Dropdown = ({ submenus, dropdown, depthLevel, setCollapseMenu }) => {
  const { t, i18n } = useTranslation();
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";
  return (
    <ul
      style={{
        direction: i18n.language == "ar" ? "rtl" : "ltr",
        // right: i18n.language == "ar" ? "50%" : "0%",
        // [i18n.language === "en" ? "left" : "right"]: "0",
      }}
      className={`dropdown-menu ${dropdownClass} ${dropdown ? "show" : ""}`}
      role={"menu"}
    >
      {submenus.map((submenu, index) => (
        <MenuItems
          depthLevel={depthLevel}
          items={submenu}
          key={index}
          setCollapseMenu={setCollapseMenu}
        />
      ))}
    </ul>
  );
};

export default Dropdown;
