import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
    FacebookIcon,
    FacebookShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    TwitterIcon,
    TwitterShareButton,
    TelegramIcon,
    TelegramShareButton,
    XIcon
} from "react-share";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import Xtwitter from "../../../src/assets/images/icon/Xtwitter.svg";


const MyVerticallyCenteredModal = ({ show, onHide, bookTitle, shareUrl }) => {
    const { t, i18n } = useTranslation();

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton style={{direction: 'ltr'}}>
                <Modal.Title id="contained-modal-title-vcenter">
                {t('mediacontent.share_social')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"d-flex w-75 justify-content-between mx-auto"}>
                <FacebookShareButton url={shareUrl} quote={bookTitle} hashtag={"#دارة_الملك_عبدالعزيز..."}>
                    <FacebookIcon size={40} round={true}/>
                </FacebookShareButton>
                <WhatsappShareButton url={shareUrl} quote={bookTitle} hashtag={"#دارة_الملك_عبدالعزيز..."}>
                    <WhatsappIcon size={40} round={true}/>
                </WhatsappShareButton>
                <TelegramShareButton url={shareUrl} quote={bookTitle} hashtag={"#دارة_الملك_عبدالعزيز..."}>
                    <TelegramIcon size={40} round={true}/>
                </TelegramShareButton>
                <TwitterShareButton url={shareUrl} quote={bookTitle} hashtag={"#دارة_الملك_عبدالعزيز..."}>
                <img
                    src={Xtwitter}
                    height={"40px"}
                    width={"40px"}
                    style={{
                        backgroundColor: "#818585",
                        borderRadius: "100%",
                        padding:"5px"
                    }}
                />
                </TwitterShareButton>
            </Modal.Body>
            <Modal.Footer dir={i18n.language === "en" ? "ltr" : "rtl"}>
                <Button className="btn_newDesign btn_newDesign_md" onClick={onHide}>{t('personalcontent.cancel')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MyVerticallyCenteredModal;