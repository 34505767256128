import parse, { domToReact } from "html-react-parser";
import img from "../assets/images/article_default.svg"


export const htmlProcessingOptions = {
    replace: ({ attribs, children }) => {
        if (!attribs) {
            return;
        }
        if (attribs.class === "hidden-xs") {
            return <></>;
        }
        if (attribs.class === "go-right") {
            return <div>{domToReact(children, htmlProcessingOptions)}</div>;
        }
    },
}

export const defaultArticleImage = "https://ab-hwc.nhp.gov.in/download/document/recent.jpg";
export const defaultAuthorImage = "https://tameer.com.eg/wp-content/uploads/2020/07/placeholder.png"
// export const defaultNewsImage = "https://fulbrightscholars.org/themes/custom/de_theme/src/img/default/article_default.svg"
export const defaultImage = img

export const CorsProxy = "https://corsproxy.io/?"

export const ParseLabelToOption  = (label) => parse(`${label}`, htmlProcessingOptions)