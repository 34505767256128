import React, {useContext} from 'react';
import {Link, useNavigate} from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import {FaSwatchbook} from "react-icons/fa";
import {GiBookAura} from "react-icons/gi";
import {useAuth} from "../../context/authContext/authContext";
import {CartContext} from "../../context/CartContext/CartContext";
import './header.css'
import {useTranslation} from "react-i18next";

const CartIcon = () => {
    const {t, i18n} = useTranslation();
    const { isAuthenticated } = useAuth();
    const { userCart } = useContext(CartContext);
    const price_unit = i18n.language == "ar" ? "ر.س" : 'SAR' ;
    let navigate=useNavigate()
    return (
        <div className="left-list-icon">
            <div className={`position-relative d-flex justify-content-center align-items-center ${i18n.language === "en" ? "ltr" : ""}`}>
                        <span style={{["left"]: "-3px", top: "-11px", right: i18n.language === "en" ? "auto" : ""}} className="badge badge-warning badgeCart" id="lblCartCount">
                        {" "}
                            {userCart?.totalQty ? userCart.totalQty : 0}{" "}
                        </span>
                <Dropdown >
                    <Link to={"/cart"}>
                    <Dropdown.Toggle className={"cartIcon p-0"} id="dropdown-basic">
                        <i class="fal fa-shopping-cart text-white fs-6 mx-1"></i>
                    </Dropdown.Toggle>
                    </Link>
                    {/* <Dropdown.Menu className={"cartDropdown"} dir={i18n.language === "en" ? "ltr" : "rtl"} >
                        {!userCart ? (
                            <center style={{ minWidth: "220px" }}>
                                <FaSwatchbook
                                    className={"my-3"}
                                    size={"3rem"}
                                    color={"#f4ac3c"}
                                />
                                <p className={"fs-5"} style={{ color: "#f4ac3c" }}>
                                {t('personalcontent.havent_reserved')}
                                </p>
                            </center>
                        ) : (
                            <div  className='myreviewsContainer'>
                                {userCart?.items?.map((item) => (
                                    
                                    (item?.product ?
                                        <Dropdown.Item
                                            className={"border-bottom mb-1 element-without-hover d-flex align-items-center"}
                                            key={item.id}
                                            style={{height:"100px"}}
                                           
                                        >
                                            <div className={"d-flex align-items-center "}>
                                                <Link to={`/books/${item?.product?.slug}`}>
                                                <div>
                                                    {isAuthenticated ?
                                                        <>
                                                            {item?.product?.image ? (
                                                                <img alt={` صورة كتاب ${item?.product?.name}`} className="book_img" src={item?.product?.image?.main} />
                                                            ) : (
                                                                <GiBookAura className="book_img" />
                                                            )}
                                                        </>
                                                        :
                                                        <>
                                                            {item?.image ? (
                                                                <img src={item?.image} className="book_img"  alt={"صورة الكتاب"}/>
                                                            ) : (
                                                                <GiBookAura className="book_img" />
                                                            )}
                                                        </>
                                                    }
                                                </div>
                                                </Link>    
                                                <div>
                                                    <Link to={`/books/${item?.product?.slug}`}>
                                                    <p className={"fs-6 mb-0 singleLine initialText"}> {item?.product?.name}</p>
                                                    {isAuthenticated ?
                                                        item?.product?.price && (
                                                            <span className="book_price fs-6 my-1">
                                                                              {(item?.product?.discount !== 0 || null) ?
                                                                                  (<>
                                                                                      <s>{`${item?.product?.price}.00`} {price_unit}{" "}</s>
                                                                                      <span className={"me-1"}>{`${(item?.product?.price_after_discount)}.00`} {price_unit}{" "}</span>
                                                                                  </>)
                                                                                  : (<>
                                                                                      {`${(item?.product?.price - item?.product?.discount)}.00`} {price_unit}{" "}
                                                                                  </>)}
                                                            </span>
                                                        )
                                                        : item?.price}
                                                    <div className={"my-1"}>
                                                    {t('personalcontent.quantity')}:
                                                        {" "}
                                                        {item?.quantity}
                                                    </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Dropdown.Item>
                                        : '')
                                ))}                               
                            </div>
                        )}
                        <Dropdown.Item style={{height:"100px"}} className='element-without-hover '>
                        <Link to={"/cart"}>
                            <div className={"submitBtn text-center"}>
                            {t('personalcontent.cart_details')}
                            </div>
                        </Link>
                        </Dropdown.Item>
                       
                    </Dropdown.Menu> */}
                </Dropdown>
            </div>
        </div>
    );
};

export default CartIcon;