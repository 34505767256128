import { FaStar } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const colors = {
    yellow: "#FFCC19",
    white: "#D2D2D2"

};

const RatingStarsStatic = ( {productRate} ) => {
    const currentValue = productRate;
    const stars = Array(5).fill(0)
    const {i18n} = useTranslation()

    return (
            <div className={`ratingStarsStaticContainer ${i18n.language === "en" ? "ltr" : ""}`}>
                {stars.map((_, index) => {
                    return (
                        <FaStar
                            key={index}
                            size={20}
                            color={(currentValue) > index ? colors.yellow : colors.white}
                        />
                    )
                })}
            </div>
    );
};
export default RatingStarsStatic;
