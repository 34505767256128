import { axios, baseURL, config } from "../../axios";

export const getSettingsData = () => {
    return axios(baseURL + "settings", config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
};
