import Card from "react-bootstrap/Card";
import styles from "./books.module.css";
import "./book-card.css";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { CartContext } from "../../../context/CartContext/CartContext";
import { useAuth } from "../../../context/authContext/authContext";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { GiSoundOn } from "react-icons/gi";
import { addBooktoFavorite } from "../../../api/services/book-api/favorite-api";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { defaultImage } from "../../../common";

const BookCard = ({ book, isRelated = false }) => {
  const { addToLocalStorage } = useContext(CartContext);
  const { isAuthenticated } = useAuth();
  const { t, i18n } = useTranslation();
  const [quantity, setQuantity] = useState(1);
  const [favorite, setFavorite] = useState(book?.is_wishlist);
  const navigate = useNavigate();


  const addToCart = async (book, quantity) => {
    
    const res = await addToLocalStorage(
      book?.author?.name,
      book?.count_sold,
      book?.count_stock,
      book?.discount,
      book?.image?.main,
      book?.is_new,
      book?.is_special,
      book?.is_wishlist,
      book?.name,
      book?.price,
      book?.price_after_discount,
      book?.rate,
      book?.slug,
      parseInt(quantity),
    );

    if (res?.lastPiece) {
      toast.warning(
        i18n.language === "ar"
            ? "لا يمكنك إضافة أكثر من نسخة واحدة من هذا الكتاب إلى السلة."
            : "You can't add more than one copy of this book to your cart.",
        {
            position: "top-left",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            style: {
                direction: i18n.language == "en" ? "ltr" : "rtl",
            },
        }
    );  
    }else{
      toast.success(
        i18n.language === "ar"
            ? "تمت الإضافة للسلة بنجاح"
            : "Added to cart successfully",
        {
            position: "top-left",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            style: {
                direction: i18n.language == "en" ? "ltr" : "rtl",
            },
        }
    );  
  }
};

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [])

  const toggleFavorite = (id) => {
    if (isAuthenticated) {
      setFavorite(!favorite);
      addBooktoFavorite(id);
      ShowMessage(
        t(
          `personal_profile.${
            !favorite ? "added_to_favorite" : "removed_from_favorite"
          }`
        ),
        !favorite
      );
    } else {
      toast.error(
        i18n.language == "ar"
          ? "يجب تسجيل الدخول للإستفادة من هذه الميزة"
          : "You must log in to benefit from this feature",
        {
          position: "top-left",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: { direction: i18n.language == "en" ? "ltr" : "rtl" },
        }
      );
    }
  };

  const [isClicked, setIsClicked] = useState(false);
  const ShowMessage = (msg, isSuccess) => {
    toast[isSuccess ? "success" : "error"](msg, {
      position: "top-left",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {i18n.language == "ar" ? "غير متوفر" : "Unavailable now"}
    </Tooltip>
  );

  const price_unit = i18n.language == "ar" ? "ر.س" : "SAR";
  return (
    <Card
      className={`${
        styles.cardStore
      } pt-3 fs-6 d-flex flex-column justify-content-center mb-3 ${
        book?.price_after_discount == 0 ? "pb-0" : "pb-4"
      }`}
    >
      {/* {book?.is_new || book?.is_special ?
                  <span className={`newBookBadge ${i18n.language == "en" ? "newBookBadge" : "newBookBadgeRtl"} ${book?.is_new ? 'is-new' : ''} ${book?.is_special ? 'is-special' : ''} ${book.is_new && book.is_special ? ' is-new-and-special' : ''}`}>
                    {book?.is_new ? `${t('openaccessandmedia.recent_releases')} ` : ''}
                    {book?.is_special ? `${t('openaccessandmedia.special_editions')} `: ''}
                    {book?.is_new && book?.is_special ? '': ''}
                  </span>
          : ''} */}
      <div
        className="d-flex justify-content-center align-items-center flex-column pb-1 cursor-pointer"
        onClick={() => navigate(`/books/${book?.slug}`)}
      >
        <div className={`${styles.cardImageContainer} overflow-hidden mt-2`}>
          <Card.Img
            src={book?.image?.main || defaultImage}
            className="mx-auto"
            alt={`${book?.name} صورة كتاب `}
            style={{
              display: "block",
              width: "100%",
              height: "100%",
              // marginTop: "10px",
              borderRadius: "10px",
              objectFit: "cover",
            }}
            onError={(e) => { e.target.src = defaultImage; }}
          />
          <div
            onClick={(e) => {
              e.stopPropagation();
              toggleFavorite(book?.slug);
            }}
            className={`${styles.heartIcon} ${styles.socialIcon} ${
              favorite ? styles.activeFavorite : ""
            } d-flex justify-content-center align-items-center`}
            style={{
              borderRadius: "50%",
              position: "absolute",
              top: "15px",
              right: "15px",
              width: "40px",
              height: "40px",
            }}
          >
            {/* <Heart color={"#96959A"} /> */}
            <svg
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              style={{
                stroke: "#A19D86",
                strokeWidth: "1.5",
                strokeLinecap: "round",
                strokeLinejoin: "round",
              }}
            >
              <path d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.69001C2 5.60001 4.49 3.10001 7.56 3.10001C9.38 3.10001 10.99 3.98001 12 5.34001C13.01 3.98001 14.63 3.10001 16.44 3.10001C19.51 3.10001 22 5.60001 22 8.69001C22 15.69 15.52 19.82 12.62 20.81Z" />
            </svg>
          </div>

          {book?.is_new ? (
            <span
              className={`newHomeFavBookBadge ${
                book?.is_new ? "newLabel" : ""
              }`}
            >
              {book?.is_new
                ? `${t("openaccessandmedia.latest_releases")} `
                : ""}
            </span>
          ) : null}
        </div>
      </div>
      <Card.Body className={"text-right px-3 py-2"}>
        <div className={"d-flex justify-content-between w-100 "}>
          <Link to={`/books/${book?.slug}`} className="w-100">
            <Card.Title
              style={{ color: "#425260", fontSize: "18px", fontWeight: "700" }}
              className={`${styles.listColor} ${styles.bookTitleSize} w-100  ${
                styles.singleLine
              } ${
                i18n.language == "en" ? styles.singleLine : styles.singleLineRtl
              }`}
            >
              {book?.name}
            </Card.Title>
          </Link>
        </div>

        <Card.Text
          style={{ color: "#96959A", fontSize: "1rem", fontWeight: "700" }}
          className={`mb-2 ${styles.bookAuthorSize} ${styles.descBook} ${
            styles.singleLine
          } ${
            i18n.language == "en" ? styles.singleLine : styles.singleLineRtl
          }`}
        >
          {t("openaccessandmedia.authorship")}: {book?.author?.name}
        </Card.Text>
      </Card.Body>
      {book?.price_after_discount === 0 && book?.voice_book === null ? (
        <>
          <Link
            style={{ marginTop: isRelated ? "1.25rem" : "" }}
            to={`/books/${book?.slug}`}
          >
            <div
              role="button"
              // onClick={() => onDelete(favorite?.product?.slug)}
              className={styles.download}
            >
              <svg
                className="mx-2"
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M8.26758 9.73328L10.4009 11.8666L12.5342 9.73328"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4004 3.33337V11.8084"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.1673 10.15C17.1673 13.8334 14.6673 16.8167 10.5007 16.8167C6.33398 16.8167 3.83398 13.8334 3.83398 10.15"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {t("FreeBook")}
            </div>
          </Link>
        </>
      ) : book?.voice_book !== null ? (
        <>
          <Link
            style={{ marginTop: isRelated ? "1rem" : "" }}
            to={`/books/${book?.slug}`}
          >
            <div
              role="button"
              // onClick={() => onDelete(favorite?.product?.slug)}
              className={styles.download}
            >
              <GiSoundOn className="mx-2" size={25} />
              {t("ListenNow")}
            </div>
          </Link>
        </>
      ) : (
        <div
          className={`d-flex justify-content-between align-items-center mt-1 px-3`}
          dir={i18n.language === "en" ? "ltr" : "rtl"}
        >
          <Card.Text
            className={`mb-0 fs-5 ${styles.priceBook}`}
            style={{ color: "#A19D86" }}
          >
            {book?.discount !== 0 || null ? (
              <>
                <span>
                  {`${book?.price_after_discount}`} {price_unit}{" "}
                </span>
                <s style={{ color: "#EB5757" }} className={"me-3"}>
                  {`${book?.price}`} {price_unit}{" "}
                </s>
              </>
            ) : (
              <>
                {`${book?.price_after_discount}`} {price_unit}{" "}
              </>
            )}
          </Card.Text>
          
            
            {book?.count_stock == 0 ? 
            <OverlayTrigger
                placement="right"
                delay={{ show: 150, hide: 400 }}
                overlay={renderTooltip}
              >
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  backgroundColor: "#A19D86",
                  color: "white",
                  width: "2rem",
                  height: "2rem",
                  fontSize: "1.75rem",
                  cursor: "default",
                  borderRadius: "5px",
                }}
              >
                +
              </div>
              </OverlayTrigger>
              :
              <div
                onClick={() => addToCart(book, quantity)}
                className="d-flex align-items-center justify-content-center"
                style={{
                  backgroundColor: "#A19D86",
                  color: "white",
                  width: "2rem",
                  height: "2rem",
                  fontSize: "1.75rem",
                  cursor: "pointer",
                  borderRadius: "5px",
                }}
              >
                +
              </div>
              }
        </div>
      )}
    </Card>
  );
};

export default BookCard;
