import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/authContext/authContext";
import { cancelServiceOrder, getServiceOrderTrack } from "../../api/services/Eservice-api";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import OrderEditModal from "./order-edit-modal";
import { ElectronicServiceRequestDetailsModal } from "./electronic-service-request-details-modal";
import { ElectronicServiceRequestCancelModal } from "./electronic-service-request-cancel";
import SendAttachmentModal from "./send-attachment-modal";
import { toast } from "react-toastify";
import { ElectronicServiceRequestDateModal } from "./electronic-service-request-date-modal";

const OrderItem = ({order, index, selectedTab, setOrders, orders, ON_DEMAND, UNDER_REVIEW, CANCELLED, FINISHED}) => {
    const { t, i18n } = useTranslation();
    const [showEditService, setShowEditService] = useState(false);
    const [showServiceDetails, setShowServiceDetails] = useState(false);
    const [showDateService, setShowDateService] = useState(false);
    const [showAttachmentService, setShowAttachmentService] = useState(false);
    const [showCancelService, setShowCancelService] = useState(false);
    const [orderDetails, setOrderDetails] = useState({})
    const [tracks, setTracks] = useState([]);
    const { profileData } = useAuth();
    const [selectedCode, setSelectedCode] = useState("")

    useEffect(() => {
        getServiceOrderTrack(order?.code).then(res => {
            setTracks(res.data.data);
        })
    }, [order?.code]);

    const addTrack = (values) => {
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        });

        const updatedTrack = {
            created_by: {
                name: profileData?.name
            },
            user_note: values.user_note,
            attachment: values.attachment,
            created_at: formattedDate,
            status: "request"
        };
        setTracks([...tracks, updatedTrack]);
    };

    const handleLastAttachment = () => {
        const lastAttachment = tracks?.length > 0 ? tracks[tracks.length - 1]?.attachment : null;
        if (!lastAttachment) return;
        window.open(lastAttachment, '_blank')
    }

    const cancelService = async (code) => {
        try {
            await cancelServiceOrder(code)
        } catch(err) {

        }
        setOrders({data: orders.filter(order => order.code !== code)})
        toast.success(i18n.language == "ar" ? 'تم الالغاء بنجاح!' : "Cancelled Successfully");
    }

    return <>
     <div
        className={`order-details shadow-sm ${index !== 0 ? "mt-3" : ""}`}
        key={order?.code || index}
    >
        <div className={"order-header"}>
            <span>
               <bdi>
                  {t("personal_profile.order_number")}:
                  <span>
                     <bdi>#{order?.code}</bdi>
                  </span>
               </bdi>
            </span>
            <span>
               <bdi>
                  {t("personal_profile.order_date")}:<span>{moment(order?.created_at)?.format('LL')}</span>
               </bdi>
            </span>
            <span>
               <bdi>
                  {t("personal_profile.total_price")}:
                    <span>
                    <bdi>
                        {order?.service?.price_after_discount > 0 ? <>
                        {order?.service?.price_after_discount} {t("service_form.service_detail.SAR")}
                        </> : <>
                        {order?.service?.price === 0 ? (
                        t("Free")
                        ) : (
                        `${order?.service?.price} ${t("service_form.service_detail.SAR")}`
                        )}
                        </>}
                    </bdi>
                    </span>               
                </bdi>
            </span>
        </div>

        <div>
            {index !== 0 ? (
                <div className={"px-3"}>
                    <hr />
                </div>
            ) : (
                ""
            )}
            <div className={`p-3`}>
                <div
                    className={
                        "d-flex justify-content-between align-items-start gap-1"
                    }
                >
                    <div>
                        <div>
                            <span style={{color: "#425260"}} className={"service_name_title"}>{t("personalcontent.service_name")}: </span>
                            <span className={"service_request_name"}>{order?.service?.name}</span>
                        </div>
                        <div className={"mt-2"}>
                            <span style={{color: "#425260"}}>{t("personalcontent.notes")}: </span>
                            {tracks && tracks.length > 0 && (
                                <span style={{color: "#425260"}} className={"detailed-value"}>
                                    {tracks[tracks.length - 1]?.user_note || tracks[tracks.length - 1]?.darah_note || orderDetails?.note || "لا توجد ملاحظات"}
                                </span>
                            )}
                        </div>
                        {tracks?.length > 0 ?
                        tracks[tracks.length - 1]?.attachment ?
                            <>
                            <button className={"d-flex justify-content-center align-items-center main border-0 p-2 mt-2 rounded attachment_button"} onClick = {handleLastAttachment}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.6654 6.66668V10C14.6654 13.3333 13.332 14.6667 9.9987 14.6667H5.9987C2.66536 14.6667 1.33203 13.3333 1.33203 10V6.00001C1.33203 2.66668 2.66536 1.33334 5.9987 1.33334H9.33203" stroke="#A19D86" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M14.6654 6.66668H11.9987C9.9987 6.66668 9.33203 6.00001 9.33203 4.00001V1.33334L14.6654 6.66668Z" stroke="#A19D86" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span className={"mx-2"}>{t('All.attachment')}</span>
                            </button>
                            </> : "" 
                        : ""}
                    </div>
                    <div>
                        <Dropdown
                            id={`dropdown-button-drop-end`}
                            className={"service_request_menu"}
                            drop={"end"}
                            variant="secondary"
                            renderMenuOnMount
                            menuRole="menu"
                            menuAlign="end"
                        >
                            <Dropdown.Toggle variant="secondary" id={`dropdown-button-drop-end`} className={"service_request_action"}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"/></svg>
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ maxWidth: '200px' }}>
                                <Dropdown.Item onClick={() => {setShowServiceDetails(true); setSelectedCode(order?.code)}}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.3866 8.00001C10.3866 9.32001 9.31995 10.3867 7.99995 10.3867C6.67995 10.3867 5.61328 9.32001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C9.31995 5.61334 10.3866 6.68001 10.3866 8.00001Z" stroke="#425260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.9999 13.5133C10.3532 13.5133 12.5466 12.1267 14.0732 9.72668C14.6732 8.78668 14.6732 7.20668 14.0732 6.26668C12.5466 3.86668 10.3532 2.48001 7.9999 2.48001C5.64656 2.48001 3.45323 3.86668 1.92656 6.26668C1.32656 7.20668 1.32656 8.78668 1.92656 9.72668C3.45323 12.1267 5.64656 13.5133 7.9999 13.5133Z" stroke="#425260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <span className={"mx-2"}>{t("personalcontent.view_details")}</span>
                                </Dropdown.Item>
                                {
                                    ![CANCELLED, FINISHED].includes(selectedTab) ?
                                    <Dropdown.Item onClick={() => {setShowEditService(true); setSelectedCode(order?.code)}}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.83958 2.4L3.36624 8.19333C3.15958 8.41333 2.95958 8.84667 2.91958 9.14667L2.67291 11.3067C2.58624 12.0867 3.14624 12.62 3.91958 12.4867L6.06624 12.12C6.36624 12.0667 6.78624 11.8467 6.99291 11.62L12.4662 5.82667C13.4129 4.82667 13.8396 3.68667 12.3662 2.29334C10.8996 0.913336 9.78624 1.4 8.83958 2.4Z" stroke="#425260" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M7.92578 3.36667C8.21245 5.20667 9.70578 6.61333 11.5591 6.8" stroke="#425260" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M2 14.6667H14" stroke="#425260" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <span className={"mx-2"}>{t("personal_profile.edit")}</span>
                                    </Dropdown.Item> : ""
                                }
                                <Dropdown.Item onClick = {() => {setShowDateService(true); setSelectedCode(order?.code)}}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.2179 5.81042C3.49179 5.30296 3.84649 4.7955 4.32241 4.31948C6.47307 2.1684 9.95721 2.1684 12.1079 4.31948C14.2585 6.47056 14.2585 9.9554 12.1079 12.1065C9.95721 14.2576 6.47307 14.2576 4.32241 12.1065C3.52322 11.3071 3.02035 10.3192 2.81381 9.28628" stroke="#425260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M9.80078 10L8.28117 9.0352C8.01647 8.86832 7.80078 8.46675 7.80078 8.1382V6" stroke="#425260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M2.76798 6.47504L2.57492 3.68627" stroke="#425260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M2.76953 6.47504L5.38713 6.06638" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    <span className={"mx-2"}>{t("personalcontent.service_date")}</span>
                                </Dropdown.Item>
                                {
                                    ![CANCELLED, FINISHED].includes(selectedTab) ?
                                    <Dropdown.Item onClick = {() => setShowAttachmentService(true)}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.21484 4.33328L7.92151 2.62662L9.62818 4.33328" stroke="#425260" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M7.92188 9.45334V2.67334" stroke="#425260" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M2.66797 8C2.66797 10.9467 4.66797 13.3333 8.0013 13.3333C11.3346 13.3333 13.3346 10.9467 13.3346 8" stroke="#425260" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <span className={"mx-2"}>{t("personalcontent.upload_file")}</span>
                                    </Dropdown.Item> : ""
                                }
                                {
                                    ![UNDER_REVIEW, CANCELLED, FINISHED].includes(selectedTab) ?
                                    <Dropdown.Item className={"text-danger"} onClick= {() => {setShowCancelService(true); setSelectedCode(order?.code)}}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14 3.98665C11.78 3.76665 9.54667 3.65332 7.32 3.65332C6 3.65332 4.68 3.71999 3.36 3.85332L2 3.98665" stroke="#EB5757" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M5.66797 3.31337L5.81464 2.44004C5.9213 1.80671 6.0013 1.33337 7.12797 1.33337H8.87464C10.0013 1.33337 10.088 1.83337 10.188 2.44671L10.3346 3.31337" stroke="#EB5757" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M12.5669 6.09338L12.1336 12.8067C12.0603 13.8534 12.0003 14.6667 10.1403 14.6667H5.86026C4.00026 14.6667 3.94026 13.8534 3.86693 12.8067L3.43359 6.09338" stroke="#EB5757" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M6.88672 11H9.10672" stroke="#EB5757" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M6.33203 8.33337H9.66536" stroke="#EB5757" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <span className={"mx-2"}>{t("personalcontent.cancel_service")}</span>
                                    </Dropdown.Item> : ""
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </div>
        {showServiceDetails ? <ElectronicServiceRequestDetailsModal show={showServiceDetails} onHide={() => setShowServiceDetails(false)} selectedCode={selectedCode} /> : ""}
        {showEditService ? <OrderEditModal show={showEditService} onHide={() => setShowEditService(false)} selectedCode={selectedCode} setOrderDetails={setOrderDetails} /> : ""}
        {showDateService ? <ElectronicServiceRequestDateModal show={showDateService} onHide={() => setShowDateService(false)} tracks = {tracks} selectedCode={selectedCode}/> : ""}
        {showAttachmentService ? <SendAttachmentModal show={showAttachmentService} onHide={() => setShowAttachmentService(false)} code={order?.code} addTrack={addTrack} /> : ""}
        {showCancelService ? <ElectronicServiceRequestCancelModal show={showCancelService} onHide={() => setShowCancelService(false)} order = {order} cancelService={cancelService} /> : ""} 
    </div>
    </>
}

export default OrderItem