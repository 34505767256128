import { useState, useEffect } from "react";
import { getServiceOrders } from "../../api/services/Eservice-api";
import {useTranslation} from "react-i18next";
import { Tab, Tabs } from "react-bootstrap";
import "./electronic-service-request.css";
import OrderItem from "./OrderItem";

const ON_DEMAND = "on_demand";
const UNDER_REVIEW = "under_review";
const CANCELLED = "cancelled";
const FINISHED = "finished";

const ElectronicServiceRequest = ({ selectedOrder, setSelectedOrder }) => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedTab, setSelectedTab] = useState("on_demand");
  const [isLoaded, setIsLoaded] = useState(false);
  const { t } = useTranslation();
  const handleTabChange = (key) => {
    setSelectedTab(key);
  };

  const handleViewOrder = (order) => {
    setSelectedOrder(order);
  };

  const handleGoBack = () => {
    setSelectedOrder(null);
  };
  // const {t} = useTranslation();

  const fetchData = async () => {
    try {
      setIsLoaded(true);
      const data = await getServiceOrders();
      setOrders(data.data);
    } catch (err) {
      console.log(err);
    }
    setIsLoaded(false);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  useEffect(() => {
    handleFilteredOrders(selectedTab);
  }, [orders, selectedTab]);

  const handleFilteredOrders = (selectedTab) => {
    let status = "request";
    if (selectedTab === UNDER_REVIEW) {
      status = "update";
    } else if (selectedTab === CANCELLED) {
      status = "cancel";
    } else if (selectedTab === FINISHED) {
      status = "accept";
    }
    setFilteredOrders(
      orders?.data?.filter((order) => order?.status === status)
    );
  };

  return (
    <>
      <div className={"my-requests"}>
        <Tabs
          defaultActiveKey={ON_DEMAND}
          id="orders"
          className={"p-2 shadow-sm eleTabs"}
          onSelect={handleTabChange}
        >
          <Tab
            eventKey={ON_DEMAND}
            title={t("personalcontent.under_verification")}
            className={"p-4"}
          >
            <OrderList
              orders={filteredOrders}
              currentTab={ON_DEMAND}
              selectedTab={selectedTab}
              isLoaded={isLoaded}
              setOrders={setOrders}
            />
          </Tab>
          <Tab
            eventKey={UNDER_REVIEW}
            title={t("personalcontent.under_review")}
            className={"p-4"}
          >
            <OrderList
              orders={filteredOrders}
              currentTab={UNDER_REVIEW}
              selectedTab={selectedTab}
              isLoaded={isLoaded}
            />
          </Tab>
          <Tab
            eventKey={CANCELLED}
            title={t("personalcontent.cancelled")}
            className={"p-4"}
          >
            <OrderList
              orders={filteredOrders}
              currentTab={CANCELLED}
              selectedTab={selectedTab}
              isLoaded={isLoaded}
            />
          </Tab>
          <Tab
            eventKey={FINISHED}
            title={t("personalcontent.finished")}
            className={"p-4"}
          >
            <OrderList
              orders={filteredOrders}
              currentTab={FINISHED}
              selectedTab={selectedTab}
              isLoaded={isLoaded}
            />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};
const OrderList = ({
  orders,
  currentTab,
  selectedTab,
  isLoaded,
  setOrders,
  productAction,
  orderInfo,
}) => {
  const { t, i18n } = useTranslation();

  if (currentTab !== selectedTab) {
    return null;
  }

  if (isLoaded) {
    return (
      <div className={"d-flex justify-content-center align-items-center mt-5"}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (!orders || orders?.length === 0) {
    return (
      <div className={"d-flex justify-content-center align-items-center mt-5"}>
        <div style={{color: "#425260"}}>
          <h3>{t("All.no_data")}</h3>
        </div>
      </div>
    );
  }

  return orders?.map((order, index) => (
    <OrderItem key={index} index={index} order={order} selectedTab = {selectedTab} setOrders={setOrders} orders={orders} ON_DEMAND={ON_DEMAND} UNDER_REVIEW={UNDER_REVIEW} CANCELLED={CANCELLED} FINISHED={FINISHED}/>
  ));
  
};

export default ElectronicServiceRequest;
