export const videoLibrariesData = [
            {
                id: 1,
                name: 'كتاب الجيش السعودي في فلسطين',
                abstract: ' لم تضعف المناصرة للقضية الفلسطينية الأم منذ تأسيس المملكة العربية السعودية .. ففي عامي ١٣٦٧و١٣٦٨هـ تنوع الدعم والوقوف معها معنوياً وبشرياً ومادياً في معركة الشرف والكرامة دارة_الملك_عبدالعزيز',
                urls: ['https://www.youtube.com/watch?v=mVtDf_qu-QI']
            },
            {
                id: 2,
                name: 'كتاب أهل العوجا',
                abstract: 'كتاب يحقق في أصل ومعنى كلمة "العوجا" في نخوة الدولة السعودية (خيال العوجا) لما لها من قيمة تاريخية ودلالة وطنية. ويفرّق بينها وبين نخوة الملك عبدالعزيز الخاصة " انا اخو نورة المعزّي"',
                urls: ['https://www.youtube.com/watch?v=3KMtVZ4YvdA']
            },
            {
                id: 3,
                name: 'كتاب الترويح في المجتمع السعودي في عهد الملك عبدالعزيز',
                abstract: ' يوثّق الكتاب لوسائل الترويح في زمن البحث في مناطق المملكة للرجل والشاب والمرأة والطفل وعلاقة ذلك بالعوامل الدينية والجغرافيا والمادية خلاصة صوتية لكتاب : "الترويح في المجتمع السعودي في عهد الملك عبدالعزيز"',
                urls: ['https://www.youtube.com/watch?v=JbaCgt5HmCU']
            },
            {
                id: 4,
                name: 'ملخص كتاب "مضيف ثليم"',
                abstract: 'يُعرِّف هذا الملخص بـ"مضيف ثليم" الذي كان محطة توقف رئيسية علی مشارف مدينة الرياض للمسافرين الذين يقصدون الرياض للقاء الملك عبدالعزيز.',
                urls: ['https://www.youtube.com/watch?v=i4zGL4LHyfM']
            },
            {
                id: 5,
                name: 'ملخص كتاب "سياسة الملك عبدالعزيز تجاه فلسطين في حرب ١٣٦٧ه/١٩٤٨م"',
                abstract: 'يسجل هذا الملخص دراسة وثائقية لسياسة الملك عبدالعزيز تجاه فلسطين في حرب ١٣٦٧ه/١٩٤٨م، استناداً إلی عدد من المصادر السعودية والبريطانية والأمريكية.',
                urls: ['https://www.youtube.com/watch?v=UsF6GBo7HEo']
            },
            {
                id: 6,
                name: 'ملخص كتاب "غالية البقمية"',
                abstract: 'يمهد الملخص بالتعريف بغالية البقمية ويحاول تحديد تاريخ ميلادها المجهول ويشير إلی نشأتها وصفاتها وزواجها وحياتها قبل الحملة العثمانية، ويبين إسهاماتها العسكرية في التصدي لحملة مصطفی بك سنة (١٢٢٨ه ـ١٨١٣م) في تربة غرب المملكة.',
                urls: ['https://www.youtube.com/watch?v=cC8udVveFUM']
            },
            {
                id: 7,
                name: 'ملخص كتاب "نهضة الجزيرة العربية"',
                abstract: 'هذا الملخص من كتاب نهضة الجزيرة العربية الذي كتبه مولفه باللغة الإنجليزية عن معاصره لينقل به ما حدث من تطورات سياسية واقتصادية واجتماعية علی يد الملك عبدالعزيز رحمه الله خلال تأسيس المملكة العربية السعودية وتوطيد بنيانها.',
                urls: ['https://www.youtube.com/watch?v=-FqMT8zIFDU']
            },
            {
                id: 8,
                name: 'كتاب الحياة الاجتماعية والاقتصادية في الدولة السعودية الثانية',
                abstract: 'يتناول هذا الكتاب الحياة الاجتماعية والاقتصادية في الدولة السعودية الثانية الممتدة بين عام ١٢٤٠ وحتى ١٣٠٩ للهجرة ويرسم بالكلمة الواقع المعيشي الذي يعيشه ابناء الجزيزة العربية في تلك الفترة',
                urls: ['https://www.youtube.com/watch?v=EXMbz4UWHrk']
            },
            {
                id: 9,
                name: 'كتاب الاسس التاريخية والفكرية للدولة السعودية',
                abstract: 'كتاب الاسس التاريخية والفكرية للدولة السعودية محاضرة القاها خادم الحرمين الشريفين الملك سلمان بن عبدالعزيز آل سعود في الجامعة الاسلامية في المدينة المنورة في عام ١٤٣٢ للهجرة',
                urls: ['https://www.youtube.com/watch?v=tNoseb0cgPw']
            },
            {
                id: 10,
                name: 'كتاب لمحات من الماضي',
                abstract: 'مذكرات الشيخ عبدالله خياط ان هذه لمحات ولا اقول ذكريات لان الذكريات اكثر شمولا واستيعابا لشتى الاتجاهات هذه المحات تصور واقع رجل عالم ومربي له تجارب بالحياة وبلغ من العمر عتيا ،عاصر ٥ ملوك من ملوك المملكة العربية السعودية  فحظي بتقديرهم جميعا',
                urls: ['https://www.youtube.com/watch?v=DDLS9cx14ww']
            },
            {
                id: 11,
                name: 'الدرعية نشأة وتطوراً في عهد الدولة السعودية الأولى',
                abstract: 'الدرعية نشأة وتطوراً في عهد الدولة السعودية الأولى دارة الملك عبدالعزيز صدر كتاب (الدرعية نشأة وتطوراً في عهد الدولة السعودية الأولى) تأليف الدكتور عبدالله الصالح العثيمين.. والكتاب يقدم لمحة موجزة عن نشأة الدرعية وتطورها',
                urls: ['https://www.youtube.com/watch?v=fGkbD25lCOU']
            },
            {
                id: 12,
                name: 'الرحلة الملكية السعودية إلى مصر',
                abstract: '"الرحلة الملكية السعودية إلى مصر" يبرز الكتاب الأهمية التاريخية لهذه الزيارة، التي تعد حدثاً تاريخياً مهماً في ترسيخ العلاقات السعودية المصرية الأخوية.',
                urls: ['https://www.youtube.com/watch?v=9DqCga5SDXE']
            },
            {
                id: 13,
                name: 'أميانوس ماركيللينوس والجزيرة العربية',
                abstract: 'يعد الكتاب وثيقة تاريخية وسجلًا للأحداث والمواجهات التي دارت بين الفرس والأباطرة البيزنطيين.',
                urls: ['https://www.youtube.com/watch?v=uH6Xuy7XJ9Y']
            },
            {
                id: 14,
                name: 'مكتبة الملك عبدالعزيز آل سعود الخاصة',
                abstract: 'يتناول سرداً للكتب الموجودة معرفاً بها، وموضحاً أهميتها',
                urls: ['https://www.youtube.com/watch?v=uXHoa1rvEXA']
            },
];