import "./terms-and-conditions.css"
import Row from "react-bootstrap/Row";
import { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import parse, { domToReact } from 'html-react-parser';
import NavItems from "../../common/NavItems/NavItems";
import styles from './eservice.module.css';
import HeaderWithBackground2 from "../../common/HeaderWithBackground/HeaderWithBackground2";
import { getStoreTerms } from "../../api/services/Help-and-support/terms-api";
import HeaderImg from "../../assets/images/darahStore/defaultBg.png";


export const TermsAndConditions = () => {
  const { type } = useParams()
  // type = register || service
  const { t, i18n } = useTranslation();
  const [getApiData, setApiData] = useState();
  const [pageLoading, setPageLoading] = useState(true);


  const termsConditionsLinks = [
    { path: "/", label: t('nav_items.home') },
    { label: getApiData?.title, lastOne: true  },
  ];

  const fetchData = async () => {
    try {
      const data = await getStoreTerms();
      setApiData(data.data)
      setPageLoading(false);
    } catch (err) {
      console.log(err);
      setPageLoading(false);
    }
  };

  useEffect(() => {
    fetchData()
  }, [])

  const firstDescriptionApiData = getApiData?.firstDescription ? `${getApiData?.firstDescription}` : "";
  const secondDescriptionApiData = getApiData?.secondDescription ? `${getApiData?.secondDescription}` : "";
  const options = {
    replace: ({ attribs, children }) => {
      if (!attribs) {
        return;
      }

      if (attribs.id === 'list') {
        return <ul className={`fs-6 ${styles.rolesPoints}`}>{domToReact(children, options)}</ul>;
      }

      if (attribs.class === 'item') {
        return (<li>
          {domToReact(children, options)}
        </li>);
      }
    }
  };

  const firstDescription = parseDomToReact(firstDescriptionApiData, options);
  const secondDescription = parseDomToReact(secondDescriptionApiData, options);

  function parseDomToReact(data, options) {
    const arrayOfElement = []
    const dataParsed = parse(data, options);

    if (dataParsed.length !== 0) arrayOfElement.push(dataParsed)
    else return []

    if (arrayOfElement[0].length) return arrayOfElement[0]
    else return arrayOfElement;
  }
  return (<>
    <HeaderWithBackground2
      title={getApiData?.title}
      backgroundImg={!pageLoading&&getApiData?.image ? getApiData?.image : HeaderImg}
      headerBody={<NavItems links={termsConditionsLinks} />}
    />
    <Container className="termsConditions mt-5 pb-5">
      {(firstDescription.length > 0 || secondDescription.length > 0) && <Row className={"w-100 mx-auto mb-3 fs-6 px-5"}>
        <p className={`paragraph my-2 ${i18n.language == "en" ? "en" : ""} `}>
          <span className='title'>
            <strong>{getApiData?.title} :-</strong>
          </span>
          {/* <span className="titleTow"> */}
            {/* <br /> */}
          {/* </span> */}
        </p>

        <Accordion.Item >
          <Accordion.Body>
            {firstDescription}
            {/* <br /> */}
            {secondDescription}
          </Accordion.Body>
          <Accordion.Item >

          </Accordion.Item>

          <Accordion.Body>
          </Accordion.Body>
        </Accordion.Item>
      </Row>}
    </Container>
  </>);
}
