import Dropdown from "react-bootstrap/Dropdown";
import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import "./header.css";
import {useAuth} from "../../context/authContext/authContext";
import {logout} from "../../api/services/auth-api/logout-api";
import {config} from "../../api/axios";
import {useTranslation} from "react-i18next";
import { getNotificationsCount } from "../../api/services/notifications-api/notifications-api";

const NavbarIconsMobileScreen = ({setCollapseMenu}) => {
    const {t} = useTranslation();
    const {i18n} = useTranslation();
    const [language, setLanguage] = useState(i18n.language);
    const navigate = useNavigate();
    let ele = localStorage.getItem("token");
    const { isAuthenticated, updateAuthState } = useAuth();
    const [notificationsCount, setNotificationsCount] = useState(0);

    if (!ele) {
        ele = sessionStorage.getItem("token");
    }

    useEffect(() => {
        if (ele === null || ele === "") updateAuthState(false);
        else updateAuthState(true);
    }, [ele,updateAuthState]);

    const Exit = async () => {
        try {
            const response = await logout();
            if (response.data === "Done") {
                navigate("/account-management");
                // window.location.reload()
                updateAuthState(false);
                localStorage.removeItem("token");
                sessionStorage.removeItem("token");
                localStorage.removeItem("isAuthenticated");
            }
        } catch(err) {
            console.log(err)
        }
    };

    const handleLanguageChange = (newLanguage) => {
        if (newLanguage !== language) {
            setLanguage(newLanguage);
            i18n.changeLanguage(newLanguage);
            config.headers["Accept-Language"] = i18n.language;
            window.location.reload();
        }
    };
    
    useEffect(() => {
      const fetchData = async () => {
         try {
            if (isAuthenticated) {
               const res = await getNotificationsCount();
               setNotificationsCount(res?.total_count ?? 0);
            }
         } catch (err) {
            console.error("Error fetching notifications count:", err);
         }
      };

      fetchData();
   }, [notificationsCount]);
    return (<>
       <li className={`menu-items`}>
               <Dropdown className={`d-flex flex-column justify-content-${i18n.language == "en" ? "left" : "right"}`}>
                  <Dropdown.Toggle
                     className={
                        "d-flex align-items-center justify-content-between my-0 notBtnsHover rounded-0"
                     }
                     style={{fontSize: "0.8rem"}}
                  >
                     <div className={"text-capitalize"}>
                        {language === "ar" ? "اللغة" : "Language"}
                     </div>
                     
                     <i style={{padding: "6px 10px", marginTop: "7px", fontSize: "0.8rem"}} className="fas fa-angle-down fa-fw align-middle"></i>
                     
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{left: 0, right: 0}}>

                     {language === "en" ? <>
                     <Dropdown.Item
                           role="button"
                           className="py-2 notBtnsHover fw-semibold"
                           style={{borderBottom: "1px solid #A19D86" , color: "#A19D86"}}
                        >
                           English
                        </Dropdown.Item>
                        <Dropdown.Item
                             className="py-2 notBtnsHover fw-semibold"
                           role="button"
                           onClick={() => handleLanguageChange("ar")}
                           style={{color: "#A19D86"}}
                        >
                           العربية
                        </Dropdown.Item>

                     </> : <>
                     <Dropdown.Item
                           role="button"
                           onClick={() => handleLanguageChange("en")}
                           className="py-2 notBtnsHover fw-semibold"
                           style={{borderBottom: "1px solid #A19D86" , color: "#A19D86"}}
                        >
                           English
                        </Dropdown.Item>
                        <Dropdown.Item
                             className="py-2 notBtnsHover fw-semibold"
                           role="button"
                           style={{color: "#A19D86"}}
                        >
                           العربية
                        </Dropdown.Item>


                     </>}
                  </Dropdown.Menu>
                  

               </Dropdown>
            </li>

            {isAuthenticated && (
               <>
               <li onClick={() => setCollapseMenu(false)} className={`menu-items`}>
                  <Link
                     to={"/profile?tabName=notifications"}
                     className="position-relative profileMenuLinks p-0 notBtnsHover"
                  >
                     {t("header.notifications")}
                     <span
                        className="badge"
                        id="lblCartCount"
                     >
                        {" "}
                        {notificationsCount}{" "}
                     </span>
                  </Link>
               </li>
            
               <li onClick={() => setCollapseMenu(false)} className={`menu-items`}>
                  <Link
                     to={"/profile?tabName=personalProfile"}
                     className={"dropdown-item profileMenuLinks notBtnsHover"}
                  >
                     {t("header.personal_profile")}
                  </Link>
               </li>
               </>
            )}

            <li className={`menu-items`}>
               {isAuthenticated ? (
                     <Dropdown.Item onClick={() => {setCollapseMenu(false); Exit();}} className="profileMenuLinks notBtnsHover">
                           {t("header.sign_out")}        
                     </Dropdown.Item>
                  ) : (
                        <Link onClick={() => setCollapseMenu(false)} className="profileMenuLinks notBtnsHover" to={"/account-management"}>
                           {t("header.sign_in")}
                        </Link>
                  )}
            </li>

    </>);
};

export default NavbarIconsMobileScreen;