import {axios, commonURL, config} from "../axios";

export const getServiceOrders = async () => {
    try {
        const { data } = await axios.get(commonURL + "services/orders/my-orders", config);
        return data;
    } catch (error) {
        throw error;
    }
};

export const getServiceOrder = async (codeOrder) => {
    try {
        const { data } = await axios.get(commonURL + `services/orders/show/${codeOrder}`, config);
        return data;
    } catch (error) {
        throw error;
    }
}

export const postServiceOrderTrack = async (formData) => {
    try {
        const { data } = await axios.post(commonURL + "services/orders/tracks", formData, config);
        return data;
    } catch (error) {
        throw error;
    }
}

export const getServiceOrderTrack = async (codeOrder) => {
    try {
        const { data } = await axios.get(commonURL + `services/orders/tracks/${codeOrder}`, config);
        return data;
    } catch (error) {
        throw error;
    }
}

export const updateServiceOrderTrack = async (codeOrder, formData) => {
    try {
        const { data } = await axios.put(commonURL + `services/orders/update/${codeOrder}`, formData, config);
        return data;
    } catch (error) {
        throw error;
    }
}

export const cancelServiceOrder = async (codeOrder) => {
    try {
        const {data} = await axios.put(`${commonURL}services/orders/update/${codeOrder}/cancel`, {}, config);
        return data;
    } catch(error) {
        throw error
    }
}