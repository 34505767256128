import HeaderWithBackground from "../../common/HeaderWithBackground/HeaderWithBackground";
import { Container, Row, Col, Card, Tabs, Tab } from "react-bootstrap/";
import styles from "./AutherCV.module.css";
import NavItems from "../../common/NavItems/NavItems";
import "./Auther.css";
import headerImg from "../../assets/images/Darah-VR.jpg";
import { useState, useEffect, useRef} from "react";
import pdfFile from "./sample.pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Document, Page, pdfjs } from "react-pdf";
import { useReactToPrint } from "react-to-print";
import Button from "react-bootstrap/Button";
import "./sample.pdf"
import { BsDownload, BsPrinter } from "react-icons/bs";
import { HiOutlineShare } from "react-icons/hi";
import MyVerticallyCenteredModal from "../../common/ShareModal/ShareModal";
import { useParams } from 'react-router-dom';
import { getAuthersCV } from "../../api/services/auther-api/auther-CV-api";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const links = [
  { path: "/", label: "الرئيسيه" },
  { path: "/authers", label: "المؤلفون" },
  { path: "#", label: "السيره الذاتيه	" },
];

const AutherCV = () => {
  const [modalShow, setModalShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [showPrintPage, setShowPrintPage] = useState(false)
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [Autherscv, setAuthersCV] = useState("");
  const [error, setError] = useState(null);
  const {userName} = useParams();
  const death = Autherscv?.date_death ? Autherscv?.date_death : "";
  const hijrian = Autherscv?.date_death_hijrian ? Autherscv?.date_death_hijrian : "";

  useEffect(() => {
    getAuthersCV(userName)
      .then((res) => {
        setAuthersCV(res.data);
      })
      .catch((err) => {
        setError("Some error happened!");
      });
  }, [userName]);

    const shareUrl = window.location.href;
    const onButtonClick = () => {
      fetch(Autherscv?.cv).then((response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = `${Autherscv?.name}.pdf`;
          alink.click();
        });
      });
    };
      function onDocumentLoadSuccess({numPages}) {
        setTotalPages(numPages);
        setPageNumber(1);
      }
        const componentRef = useRef();
        const handlePrint = useReactToPrint({
          content: () => componentRef.current,
      });
      const handlePrintPage = () => {
          setShowPrintPage(!showPrintPage);
      }

return (
  <>
  <NavItems links={links} />
  <HeaderWithBackground
        backgroundImg={headerImg}
        className={"fs-3"}
        title={"المؤلفون"}
        headerBody={"الدارة كلمة عربية تعني الأرض الواسعة بين الجبال، أو كل ما يُحيط بالشيء، وهي تُجمع على دارات، ودارات العرب هي السهول البيض التي تُنبت ما طاب ريحه من النبات، وقد تغنى الشعراء قديماً بها حتى أن عددها زاد إلى مائة وعشر دارات، ومن أشهرها «دارة جُلجُل»." +
           ""}
      />

<Container className={"mt-5 my-5 pt-5 pt-md-3"}>
    <div className="pt-3 pt-md-1 pt-lg-0">
    {showPrintPage && <>
            <center>
                <Button className={"fs-3"} variant="warning" size="lg" onClick={handlePrint}>طباعة </Button>{' '}
                <Button className={"fs-3"} variant="secondary" size="lg"  onClick={handlePrintPage}>رجوع</Button>
            </center>
            <Document className={"bookViewContainer mx-auto justify-content-center border position-relative row"}
                      ref={componentRef} file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(totalPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={1.5}
                          renderAnnotationLayer={false}>
                    </Page>))}
            </Document>
        </>}
        {!showPrintPage && <>
  <Row className="pt-5">
    <Col lg={"3"}>
      <div className={styles.bookImageContainer}>
          <img src={Autherscv?.image?.main} alt="Book"  onClick={handleShow} />
      </div>
    </Col>
    <Col lg={"9"} className={"mt-lg-0 mt-md-2 mt-sm-2"}>   
        <>
       
          <Row className={"mt-3 p-3"} style={ {color:"#425260" }}>
            <strong className={"fs-4"}>
            {Autherscv?.name}
            </strong>
          </Row>
         <Row className="p-2">
            <Col lg={8}>
                <div className="mb-2"> <span className="newMainColor fw-bold">نبذة تعريفية:</span> {Autherscv?.abstract}</div>
                <div className="mb-2"><span className="newMainColor fw-bold">المهنه:</span> {Autherscv?.nickname}</div>
                <div className="mb-2"> <span className="newMainColor fw-bold"> البريد الالكتروني: </span> {Autherscv?.email}</div>
                <div className="mb-2"><span className="newMainColor fw-bold"> ت الميلاد هجرى: </span> {Autherscv?.birthday_hijrian}</div>
                <div className="mb-2"><span className="newMainColor fw-bold"> ت الوفاه هجرى: </span> {hijrian}</div>
            </Col>
            <Col lg={4}>
                <div className="mb-2"><span className="newMainColor fw-bold"> عدد اعمال المحرر : </span> {Autherscv?.works_count}</div>
                <div className="mb-2"><span className="newMainColor fw-bold"> ت بدايه العمل هجري : </span> {Autherscv?.start_date_hijrian}</div>
                <div className="mb-2"><span className="newMainColor fw-bold"> ت بدايه العمل ميلادي : </span> {Autherscv?.start_date}</div>
                <div className="mb-2"><span className="newMainColor fw-bold"> ت الميلاد ميلادى : </span> {Autherscv?.birthday}</div>
                <div className="mb-2"><span className="newMainColor fw-bold"> ت الوفاه ميلادى : </span> {death}</div>
            </Col>
         </Row>

          <Row className={"fs-5"} style={ {color:"#005C5D"}} >
            <Col xs={4} md={3} lg={3} className="mb-4 newMainColor" role={"button"} onClick={() => setModalShow(true)}>
              <HiOutlineShare className="newMainColor" size={"23px"} /> مشاركة
            </Col >
            <Col xs={4} md={3} lg={3} className="mb-4 newMainColor"  role={"button"} onClick={handlePrintPage} >
            <BsPrinter className="newMainColor" size={"23px"}  /> طباعة
            </Col>
            <Col xs={4} md={3} lg={3} className="mb-4 newMainColor" role={"button"} onClick={onButtonClick}>
            <BsDownload className="newMainColor" size={"23px"} /> تحميل 
            </Col>
          </Row>
          <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            shareUrl={shareUrl}
          />
        </>
    
    </Col>
  </Row>
  </>
  }
    </div>
</Container>
</>);
};

export default AutherCV;