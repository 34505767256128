import React, { createContext, useState, useContext, useEffect } from "react";

const ContrastContext = createContext();

export const ContrastProvider = ({ children }) => {
  const [enableContrast, setEnableContrast] = useState("false"); // Initial contrast level (in percentage)
  useEffect(() => {
    const isContrastEnabled = localStorage.getItem("enableContrast");
    if (isContrastEnabled == "true") {
      setEnableContrast(isContrastEnabled);
    }
  }, []);

  // Update local storage whenever contrastLevel changes
  useEffect(() => {
    localStorage.setItem("enableContrast", enableContrast.toString());
    if (enableContrast == true || enableContrast == "true") {
        document.documentElement.style.filter = "grayscale(1)";
    } else {
        document.documentElement.style.filter = "unset";
    }
  }, [enableContrast]);
  return (
    <ContrastContext.Provider value={{ enableContrast, setEnableContrast }}>
      {children}
    </ContrastContext.Provider>
  );
};

export const useContrast = () => {
  return useContext(ContrastContext);
};
