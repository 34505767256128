import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./electronic-service-request.css";
import { useEffect, useState } from "react";
import { getServiceOrder } from "../../api/services/Eservice-api";


export const ElectronicServiceRequestDetailsModal = ({ show, onHide, selectedCode }) => {
    const { t, i18n } = useTranslation();
    const [orderDetails, setOrderDetails] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
      if (show && selectedCode ) {
          getServiceOrder(selectedCode).then(res => {
              setOrderDetails(res.data);
              setIsLoading(false)
          }).catch(err => {
              console.log(err);
              setIsLoading(false)
          });
      }
      }, [selectedCode]);
    const fullName = orderDetails?.first_name + " " + orderDetails?.second_name + " " + orderDetails?.grandpa + " " + orderDetails?.family

    return (
        <>
        {isLoading ? <>
        <div>
          <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={"sendAttachmentModal custom-modal edit-request-modal"}
            dir={i18n.language === "en" ? "ltr" : "rtl"}
            style={{minHeight: "550px", minWidth: "550px"}}

          >
            <Modal.Header className={"d-flex "} closeButton>
              <Modal.Title className={"service-request-modal-title"}>
                {t("personalcontent.service_details")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{minHeight: "400px", minWidth: "500px"}} className="d-flex justify-content-center align-items-center">
                <div
                   className={"d-flex justify-content-center align-items-center"}
                >
                <div className="spinner-border text-primary" role="status">
                   <span className="visually-hidden">Loading...</span>
                </div>
             </div>
             </Modal.Body>
             </Modal>
        </div>
    
             </> :
            <div>
                <Modal
                    show={show}
                    onHide={onHide}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className={"sendAttachmentModal custom-modal edit-request-modal"}
                    dir={i18n.language === "en" ? "ltr" : "rtl"}
                >
                    <Modal.Header className={"d-flex "} closeButton>
                        <Modal.Title className={"service-request-modal-title"}>
                            {t("personalcontent.service_details")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={"service-request-form-title mb-2"}>
                            {t("personalcontent.personal_profile")}
                        </div>
                        <Row>
                            <Col lg={6} className={"my-2"}>
                                <div>
                                    <span className={"label"}>{t("service_form.first_form.full_name")}: </span>
                                    <span className={"detailed-value"}>{fullName}</span>
                                </div>
                            </Col>
                            <Col lg={6} className={"my-2"}>
                                <div>
                                    <span className={"label"}>{t("contact_us.address")}: </span>
                                    <span className={"detailed-value"}>{orderDetails?.address}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} className={"my-2"}>
                                <div>
                                    <span className={"label"}>{t("service_form.fourth_form.e_mail")}: </span>
                                    <span className={"detailed-value"}>{orderDetails?.email}</span>
                                </div>
                            </Col>
                            <Col lg={6} className={"my-2"}>
                                <div>
                                    <span className={"label"}>{t("service_form.fourth_form.phone_number")}: </span>
                                    <span className={"detailed-value reverse-text"}>{orderDetails?.phone}</span>
                                </div>
                            </Col>
                            <Col lg={12} className={"my-2"}>
                                <div>
                                    <span className={"label"}>{t("personalcontent.cv")}: </span>
                                    <span className={"detailed-value"}>
                                    <a href={orderDetails?.cv} target="_blank" rel="noopener noreferrer">
                                        <span className="mx-1 fw-bold main-color">
                                        {t('Open_attachment')}
                                        </span>
                                    </a>
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <div className={"service-request-form-title mb-2"}>
                            {t("personalcontent.order_data")}
                        </div>
                        <Row className={"fw-bold fs-6 gy-3"}>
                            {orderDetails?.form_data?.map((item, index) => (
                                <Col lg={6} key={index}>
                                    {(item.type === "file") || (item.type === "image") ?
                                        <>
                                            <span className={"label"}>{item.name}: </span>
                                            {item.value ? <>
                                                <a href={item.value} target="_blank" rel="noopener noreferrer">
                                                    <span className="mx-1 main-color">
                                                        {t('Open_attachment')}
                                                    </span>
                                                </a>
                                            </> 
                                            : (<>
                                            <span className="detailed-value">
                                            {t("no_data_found")}
                                            </span>
                                            </>)} 
                                        </>
                                        :
                                        <>
                                            <span className={"label"}>{item.name}: </span>
                                            <span className={"detailed-value"}>{item.value || t('no_data_found')}</span>
                                        </>
                                    }
                                </Col>
                            ))}
                        </Row>
                     
                    </Modal.Body>
                </Modal>
            </div>
            }
        </>
    );
}