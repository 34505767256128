import { axios, login_baseURL, config } from "../../axios";

export const logout= async()=>{
    try {
        const { data } = await axios.get(login_baseURL +"logout", config)
        return data;
    } catch (error) {
        throw error;
    }

};