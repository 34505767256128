import { axios, commonURL, config } from "../../axios";

export const getEservicesMenuItems = () => {
    return axios
        .get(commonURL + "services", config)
        .then(function (response) {
            return response.data.data.data.map((obj) => {
                const slug = obj.slug;
                const name = obj.name;
                return { title: name, url: process.env.REACT_APP_DARAH_PORTAL_URL+`e-services/service-details/${slug}` };
            })
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const getDigitalContentMenuItems = () => {
    return axios
        .get(commonURL + "categories", config)
        .then(function (response) {
            return response.data.data.data.map((obj) => {
                const slug = obj.slug;
                const name = obj.name;
                if(obj.template == "newspaper") {
                    return { title: name, url: process.env.REACT_APP_DARAH_PORTAL_URL+`digital-content/newspaper/${slug}` };
                } else if (obj.template == "journal" && (obj.type_model == "darah_journal")){
                    return { title: name, url: process.env.REACT_APP_DARAH_JOURNAL_URL, };
                }
                else if (obj.template == "journal" && (obj.type_model == "darah_journal")){
                    return { title: name, url: process.env.REACT_APP_DARAH_JOURNAL_URL+"scientific-reviews-journal"};                    
                }
                else if (obj.template == "journal" && (obj.type_model == "darah_journal")){
                    return { title: name, url: process.env.REACT_APP_DARAH_JOURNAL_URL+"journal/"+obj.slug};   
                }
                else if (obj.template == "journal"){
                    return { title: name, url: process.env.REACT_APP_DARAH_PORTAL_URL+`digital-content/magazine/${slug}` };
                }
                else if (obj.template == "encyclopedia"){
                    return { title: name, url: process.env.REACT_APP_DARAH_PORTAL_URL+`digital-content/encyclopedia/${slug}` };
                }
                else if (obj.template == "road"){
                    return { title: name, url: process.env.REACT_APP_DARAH_PORTAL_URL+`digital-content/road/${slug}` };
                }
            })
        })
        .catch(function (error) {
            console.log(error);
        });
};
