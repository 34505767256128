import ContentLoader from 'react-content-loader'
import Col from "react-bootstrap/Col";

const LoadingCard = props => {
    return (
        <div className={"row row-cols-2 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 g-2 g-lg-3 gy-4 px-2"}>
            {Array(props.cards).fill(0).map((item, index)=>
                <Col key={index}>
            <div className='d-flex justify-content-center align-items-center flex-column'>
                <ContentLoader
                    speed={1}
                    width={350}
                    height={400}
                    viewBox="80 50 400 400"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...props}
                >
                    <rect x="172" y="53" rx="0" ry="0" width="2" height="300" />
                    <rect x="386" y="55" rx="0" ry="0" width="2" height="300" />
                    <rect x="171" y="53" rx="0" ry="0" width="216" height="2" />
                    <rect x="171" y="353" rx="0" ry="0" width="216" height="2" />
                    <circle cx="277" cy="147" r="44" />
                    <rect x="174" y="53" rx="0" ry="0" width="216" height="41" />
                    <rect x="198" y="207" rx="0" ry="0" width="160" height="9" />
                    <rect x="231" y="236" rx="0" ry="0" width="92" height="9" />
                    <rect x="206" y="324" rx="0" ry="0" width="146" height="51" />
                </ContentLoader>
            </div>
                </Col>
        )}
        </div>
    )
}

LoadingCard.metadata = {
    name: 'عنوان الكتاب',
    description: 'وصف الكتاب',
    filename: 'المؤلف',
}

export default LoadingCard