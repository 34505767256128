import axios from "axios";
import i18n from "i18next";
// TODO: change baseURL to http://darah-dev.4explain.net/api/v1/ and add stores to all api except auth
const commonURL = process.env.REACT_APP_BACKEND_ENDPOINT;
const baseURL = process.env.REACT_APP_BACKEND_ENDPOINT_STORE;
const baseURLForHero=process.env.REACT_APP_BACKEND_ENDPOINT;
const login_baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;

const config = {
  headers: {
    Accept: "application/json",
    "Accept-Language": `${i18n.language}`,
    "Client-Secret": "#da565rah4HHJ5$%gtwe22134gKLMno123",
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`;
      },
    },
  },
};

export { axios, baseURL, login_baseURL, config, commonURL,baseURLForHero };
