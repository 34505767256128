import {createContext, useEffect, useState} from "react";
import {
    deleteCartBooks, getCartBooks, addBookToCart, addBookToCartBlucks
} from "../../api/services/book-api/books-in-cart-api";
import {useAuth} from "../authContext/authContext";

export const CartContext = createContext();

export const CartProvider = ({children}) => {
    const [userCart, setUserCart] = useState({
        items: [], totalPrice: 0, totalPriceBooks: 0, totalPriceDiscountBooks: 0, totalQty: 0, discount: 0, sub_price: 0
    });
    const [userCartCheckout, setUserCartCheckout] = useState({});

    const [userCartTotalQty, setUserCartTotalQty] = useState(null);

    const [isCartLoaded, setIsCartLoaded] = useState(false);

    const {isAuthenticated} = useAuth();

    useEffect(() => {
        // if (isAuthenticated) {
        //     const cartData = JSON.parse(localStorage.getItem("cart"));
        //     if (cartData) {
        //         cartData.items.forEach((item) => {
        //             addToCart(item.product.slug, item.quantity);
        //         });
        //         localStorage.removeItem("cart");
        //     }
        //     getCartBooks()
        //         .then((response) => {
        //             const data = response.data.data[0];
        //             setUserCart({
        //                 items: data?.items, totalPrice: data?.price, totalQty: data?.total_quantity, discount: data?.discount, sub_price: data?.sub_price
        //             });
        //         })
        //         .catch((error) => {
        //         }).finally(() => setIsCartLoaded(true));
        // } else {
        //     // Load cart from localStorage
        //     const cartData = localStorage.getItem("cart");
        //     if (cartData) {
        //         setUserCart(JSON.parse(cartData));
        //     }else{
        //         setUserCart({
        //             items: [], totalPrice: 0, totalQty: 0, discount: 0, sub_price: 0
        //         });
        //     }
        // }
        if (localStorage.getItem("cart")) {
            setUserCart(JSON.parse(localStorage.getItem("cart")))
        }
        if (isAuthenticated) {
            const addBooksAutomatically = async () => {
                if (isAuthenticated) {
                    const cartData = JSON.parse(localStorage.getItem("cart"));
                    if (cartData?.items?.length > 0) {
                        let items = [];
                        cartData.items.forEach((item) => {
                            items.push({ "product": item.product.slug, "quantity": item.quantity });
                        });
                        const res = await addBookToCartBlucks(items);
                        setUserCartCheckout(res?.data?.data?.data[0])
                    }
                    setIsCartLoaded(true);
                }
        
                // Call addBooksAutomatically again after 2 minutes
                setTimeout(addBooksAutomatically, 120000); // 120000 milliseconds = 2 minutes
            };
        
            addBooksAutomatically();
        }

    }, [isAuthenticated]);

    const addToLocalStorage = (authorName, count_sold, count_stock, discount, image, is_new, is_special, is_wishlist, bookName, price, price_after_discount, rate, slug, quantity)  => {
        // Update local storage cart with book name
        const existingCart = JSON.parse(localStorage.getItem("cart")) || {
            items: [], totalPrice: 0, totalPriceBooks: 0, totalPriceDiscountBooks: 0, totalQty: 0, discount: 0, sub_price: 0
        };
        let lastPiece = false;
        const itemIndex = existingCart.items.findIndex((item) => item.product.slug === slug);

        if (itemIndex !== -1) {
            // Item already exists, increment its quantity
            const existingItem = existingCart.items[itemIndex];
            const updatedItem = {
                ...existingItem, quantity: count_stock == 1 ? existingItem.quantity : existingItem.quantity + quantity,
            };
            existingCart.items.splice(itemIndex, 1, updatedItem);

            // debugger
            if (count_stock === 1 && existingItem.quantity === 1) {
                lastPiece = true
            }

        } else {
            // Item doesn't exist, add it to the cart
            existingCart.items.push({
                product: {slug, bookName}, quantity: quantity, price: price, image: image, priceAfterDiscount: price_after_discount, countStock: count_stock, discount: discount
            });
        }

        if (lastPiece) {
            return {lastPiece: true}
        }
        existingCart.totalQty += quantity;

        if (discount) {
            existingCart.totalPriceDiscountBooks += price_after_discount * quantity;
        }else {
            existingCart.totalPriceBooks += price * quantity;
        }
        existingCart.totalPrice = existingCart.totalPriceDiscountBooks + existingCart.totalPriceBooks

        if (userCart?.length) {
            // existingCart.totalPrice = getTotalPrice(userCart.items, null, "")
        }

        localStorage.setItem("cart", JSON.stringify(existingCart));

        // Update cart in the UI
        setUserCart(existingCart);
        setUserCartTotalQty(existingCart.totalQty);
    };

    const removeFromLocalStorageDatabaseCart = (slug) => {

        // Remove item from local storage
        if (slug) {
    
            const itemToRemove = userCart?.items?.find((cartItem) => cartItem?.product?.slug === slug);
    
            if (!itemToRemove) {
                return;
            }
    
            const existingCart = JSON.parse(localStorage.getItem('cart'));
            if (!existingCart) {
                return;
            }
            const itemQty = existingCart.items.find(item => item.product.slug === slug).quantity;
    
            let updatedCart = {
                ...existingCart,
                items: existingCart.items.filter((item) => item.product.slug !== slug),
                totalQty: existingCart.totalQty - itemQty,
                totalPrice: !itemToRemove?.discount ? existingCart.totalPrice - (itemToRemove.price * itemQty) : existingCart.totalPrice - (itemToRemove.priceAfterDiscount * itemQty),
                totalPriceBooks: !itemToRemove?.discount ? existingCart.totalPriceBooks - (itemToRemove.price * itemQty) : existingCart.totalPriceBooks,
                totalPriceDiscountBooks: itemToRemove?.discount ? existingCart.totalPriceDiscountBooks - (itemToRemove.priceAfterDiscount * itemQty) : existingCart.totalPriceDiscountBooks,
            };
    
            if (updatedCart?.items?.length === 0) {
                updatedCart = {
                    items: [], totalPrice: 0, totalPriceBooks: 0,
                    totalPriceDiscountBooks: 0, totalQty: 0,
                    discount: 0, sub_price: 0
                };
            }
    
            localStorage.setItem('cart', JSON.stringify(updatedCart));
    
            // Update cart in the UI
            setUserCart(updatedCart);
            setUserCartTotalQty(updatedCart.totalQty);
        }

        // Remove item from user's cart in the database
        if (isAuthenticated) {
        const itemDatabaseToRemove = userCartCheckout?.items?.find((cartItem) => cartItem?.product?.slug === slug);

        if (!itemDatabaseToRemove) {
            return;
        }

        deleteCartBooks(itemDatabaseToRemove.id)
            .then(() => {
                getCartBooks().then((response) => {
                    const data = response.data.data[0];
                    const updatedItems = data?.items;
                    const updatedTotalPrice = data?.price;
                    const updatedTotalQty = data?.total_quantity;

                    const updatedUserCart = {
                        items: updatedItems, totalPrice: updatedTotalPrice, totalQty: updatedTotalQty,
                    };
                    setUserCartCheckout(updatedUserCart);
                });
            })
            .catch((error) => {
            });
        }

     
 }

    const addToCart = (slug, qty) => {
        if (!isAuthenticated) {
            return;
        }

        // const existingItem = userCart?.items?.find(
        //     (cartItem) => cartItem?.product?.slug === slug
        // );

        // if (existingItem) {
        //     const updatedQty = existingItem.quantity ;
        //     const updatedItem = {
        //         ...existingItem,
        //         quantity: updatedQty
        //     };
        //     setUserCart({
        //         ...userCart,
        //         items: userCart.items.map((cartItem) =>
        //             cartItem.product.slug === slug ? updatedItem : cartItem
        //         ),
        //         totalQty: userCart.totalQty ,
        //     });
        //     addBookToCart(slug, updatedItem.quantity)
        //         .then(() => {
        //             getCartBooks().then((response) => {
        //                 const data = response.data.data[0];
        //                 setUserCart({
        //                     items: data.items,
        //                     totalPrice: data.price,
        //                     totalQty: data.total_quantity,
        //                 });
        //             });
        //         })
        //         .catch((error) => {
        //         });
        // } else {
        //     addBookToCart(slug, qty)
        //         .then(() => {
        //             getCartBooks().then((response) => {
        //                 const data = response.data.data[0];
        //                 setUserCart({
        //                     items: data.items,
        //                     totalPrice: data.price,
        //                     totalQty: data.total_quantity,
        //                 });
        //             });
        //         })
        //         .catch((error) => {
        //         });
        // }
    };


    const removeFromCart = (slug) => {
        const itemToRemove = userCart?.items?.find((cartItem) => cartItem?.product?.slug === slug);

        if (!itemToRemove) {
            return;
        }

        if (isAuthenticated) {
            // Remove item from user's cart in the database
            deleteCartBooks(itemToRemove.id)
                .then(() => {
                    getCartBooks().then((response) => {
                        const data = response.data.data[0];
                        const updatedItems = data?.items;
                        const updatedTotalPrice = data?.price;
                        const updatedTotalQty = data?.total_quantity;

                        const updatedUserCart = {
                            items: updatedItems, totalPrice: updatedTotalPrice, totalQty: updatedTotalQty,
                        };
                        setUserCart(updatedUserCart);
                    });
                })
                .catch((error) => {
                });
        }

        // Remove item from local storage
        const existingCart = JSON.parse(localStorage.getItem('cart'));
        if (!existingCart) {
            return;
        }

        const itemQty = existingCart.items.find(item => item.product.slug === slug).quantity;
        let updatedCart = {
            ...existingCart,
            items: existingCart.items.filter((item) => item.product.slug !== slug),
            totalQty: existingCart.totalQty - itemQty,
            totalPrice: existingCart.totalPrice - (itemToRemove.price * itemQty),
        };

        if (updatedCart?.items?.length === 0) {
            updatedCart = {
                discount: 0,
                items: [],
                sub_price: 0,
                totalPrice: 0,
                totalQty: 0,
            };
        }

        localStorage.setItem('cart', JSON.stringify(updatedCart));

        // Update cart in the UI
        setUserCart(updatedCart);

    };

    const updateCartItemQuantity = (slug, newQty) => {
        const existingItem = userCart?.items?.find((cartItem) => cartItem?.product?.slug === slug);

        if (!existingItem) {
            return;
        }

        const updatedItem = {
            ...existingItem, quantity: newQty
        };

        let updatedCart = {
            ...userCart,
            items: userCart.items.map((cartItem) => cartItem.product.slug === slug ? updatedItem : cartItem),
            totalQty: userCart.totalQty - existingItem.quantity + newQty,
            totalPrice: getTotalPrice(userCart.items, slug, newQty),
        }
        setUserCart(updatedCart);

        if (!isAuthenticated) {
            localStorage.setItem('cart', JSON.stringify(updatedCart));
            return;
        }

        addBookToCart(slug, newQty)
            .then(() => {
                getCartBooks().then((response) => {
                    const data = response.data.data[0];
                    setUserCart({
                        items: data.items, totalPrice: data.price, totalQty: data.total_quantity,
                    });
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const addBooksCheckout = async () => {
        if (isAuthenticated) {
            const cartData = JSON.parse(localStorage.getItem("cart"));
            if (cartData) {
                let items = [];
                cartData.items.forEach((item) => {
                    items.push({ "product": item.product.slug, "quantity": item.quantity });
                });
                const res = await addBookToCartBlucks(items);
                setUserCartCheckout(res?.data?.data?.data[0])
            }
            setIsCartLoaded(true);
        }
    };


    const getTotalPrice = (items, slug, newQty) => {
        let totalPrice = userCart.items.reduce((total, item) => {
            if (item.product.slug === slug) {
                return total + ((item?.priceAfterDiscount || item.price) * newQty)
            }
            return total + ((item?.priceAfterDiscount || item.price) * item.quantity)
        }, 0);
        return Number.isInteger(totalPrice) ? totalPrice : Number(totalPrice?.toFixed(2))
    }

    const clearCart = () => {
        setUserCart({
            items: [], totalPrice: 0, totalPriceBooks: 0, totalPriceDiscountBooks: 0, totalQty: 0, discount: 0, sub_price: 0
        });
    };

    return (<CartContext.Provider
            value={{userCart, setUserCart, addToCart, removeFromCart, updateCartItemQuantity, clearCart, addToLocalStorage, isCartLoaded, userCartTotalQty, removeFromLocalStorageDatabaseCart, addBooksCheckout, userCartCheckout}}>
            {children}
        </CartContext.Provider>);
};
