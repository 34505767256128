import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import "./electronic-service-request.css";
import { useEffect, useState } from "react";
import { getServiceOrder } from "../../api/services/Eservice-api";

export const ElectronicServiceRequestDateModal = ({ show, onHide, tracks, selectedCode }) => {
    const { t, i18n } = useTranslation();
    const [orderDetails, setOrderDetails] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
      if (show && selectedCode ) {
          getServiceOrder(selectedCode).then(res => {
              setOrderDetails(res.data);
              setIsLoading(false)
          }).catch(err => {
              console.log(err);
              setIsLoading(false)
          });
      }
      }, [selectedCode]);

    const getFormattedDate = (date) => {
        const englishDate = new Date(date);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return englishDate.toLocaleDateString(i18n.lang === "en" ? 'en-US' : 'ar-AR', options);
    }

    return (
        <>
        {isLoading ? <>
        <div>
            <Modal
                show={show}
                onHide={onHide}
                backdrop="static"
                keyboard={false}
                size="lg"
                className={"custom-modal service-details-modal"}
                dir={i18n.language === "en" ? "ltr" : "rtl"}
                style={{minHeight: "550px", minWidth: "550px"}}
            >
            <Modal.Header className={"d-flex "} closeButton>
                <Modal.Title className={"service-request-modal-title"}>
                    {t("personalcontent.service_date")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{minHeight: "400px", minWidth: "500px"}} className="d-flex justify-content-center align-items-center">
                <div
                   className={"d-flex justify-content-center align-items-center"}
                >
                <div className="spinner-border text-primary" role="status">
                   <span className="visually-hidden">Loading...</span>
                </div>
             </div>
             </Modal.Body>
             </Modal>
        </div>
        </> :
            <div>
                <Modal
                    show={show}
                    onHide={onHide}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    className={"custom-modal service-details-modal"}
                    dir={i18n.language === "en" ? "ltr" : "rtl"}
                >
                    <Modal.Header className={"d-flex "} closeButton>
                        <Modal.Title className={"service-request-modal-title"}>
                            {t("personalcontent.service_date")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {tracks?.map((track, index) => (
                                <>
                                    <div className={"service-request-form-title mb-3"}>
                                        <bdi>{getFormattedDate(track?.created_at)}</bdi>
                                    </div>
                                    <div className={"my-2"}>
                                        <span className={"label"}>{t("personalcontent.note")}: </span>
                                        <span className={"detailed-value"}>
                                        {track?.user_note ? (track?.user_note) : (track?.darah_note ? track?.darah_note : orderDetails?.note ? orderDetails?.note : i18n.language == "ar" ? "لا توجد ملاحظات" : "There are no notes")}
                                        </span>                                   
                                    </div>
                                    <div className={"my-2"}>
                                        <span className={"label"}>{t("personalcontent.note_provider")}: </span>
                                        <span className={"detailed-value"}>{track?.created_by?.name || (i18n.language === "ar" ? "لا توجد بيانات" : "There is no data")}</span>
                                    </div>
                                    {
                                        track?.attachment ? (
                                            <div className={"my-2"} onClick = {() => window.open(track?.attachment, '_blank')}>
                                                <span className={"label"}>{t("service_form.service_detail.attachments")}: </span>
                                                <div>
                                                    <button className={"d-flex justify-content-center align-items-center main border-0 p-2 mt-2 rounded attachment_button"}>
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M14.6654 6.66668V10C14.6654 13.3333 13.332 14.6667 9.9987 14.6667H5.9987C2.66536 14.6667 1.33203 13.3333 1.33203 10V6.00001C1.33203 2.66668 2.66536 1.33334 5.9987 1.33334H9.33203" stroke="#A19D86" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                            <path d="M14.6654 6.66668H11.9987C9.9987 6.66668 9.33203 6.00001 9.33203 4.00001V1.33334L14.6654 6.66668Z" stroke="#A19D86" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                        <span className={"mx-2"}>{t("All.attachment")}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        ) : ""
                                    }

                                    <hr />
                                </>
                            ))}
                        {/*<div className={"service-request-form-title mb-3"}>*/}
                        {/*    20 اكتوبر 2023*/}
                        {/*</div>*/}
                        {/*<div className={"my-2"}>*/}
                        {/*    <span className={"label"}>{t("personalcontent.note")}: </span>*/}
                        {/*    <span className={"detailed-value"}>لا توجد بيانات</span>*/}
                        {/*</div>*/}
                        {/*<div className={"my-2"}>*/}
                        {/*    <span className={"label"}>{t("personalcontent.note_provider")}: </span>*/}
                        {/*    <span className={"detailed-value"}>لا توجد بيانات</span>*/}
                        {/*</div>*/}
                        {/*<div className={"my-2"}>*/}
                        {/*    <span className={"label"}>{t("service_form.service_detail.attachments")}: </span>*/}
                        {/*    <div>*/}
                        {/*        <button className={"d-flex justify-content-center align-items-center main border-0 p-2 mt-2 rounded attachment_button"}>*/}
                        {/*            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                <path d="M14.6654 6.66668V10C14.6654 13.3333 13.332 14.6667 9.9987 14.6667H5.9987C2.66536 14.6667 1.33203 13.3333 1.33203 10V6.00001C1.33203 2.66668 2.66536 1.33334 5.9987 1.33334H9.33203" stroke="#A19D86" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>*/}
                        {/*                <path d="M14.6654 6.66668H11.9987C9.9987 6.66668 9.33203 6.00001 9.33203 4.00001V1.33334L14.6654 6.66668Z" stroke="#A19D86" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>*/}
                        {/*            </svg>*/}
                        {/*            <span className={"mx-2"}>Attachment</span>*/}
                        {/*        </button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<hr />*/}

                        {/*<div className={"service-request-form-title mb-3"}>*/}
                        {/*    20 اكتوبر 2023*/}
                        {/*</div>*/}
                        {/*<div className={"my-2"}>*/}
                        {/*    <span className={"label"}>{t("personalcontent.note")}: </span>*/}
                        {/*    <span className={"detailed-value"}>لا توجد بيانات</span>*/}
                        {/*</div>*/}
                        {/*<div className={"my-2"}>*/}
                        {/*    <span className={"label"}>{t("personalcontent.note_provider")}: </span>*/}
                        {/*    <span className={"detailed-value"}>لا توجد بيانات</span>*/}
                        {/*</div>*/}
                    </Modal.Body>
                </Modal>
            </div>

        }
        </>
    );
}